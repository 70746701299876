// src/components/PayoutDetails.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPayoutById } from './PayoutApi';

const PayoutDetails = () => {
    const { id } = useParams();
    const [payout, setPayout] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPayout = async () => {
            const data = await getPayoutById(id);
            setPayout(data);
            setLoading(false);
        };
        fetchPayout();
    }, [id]);

    if (loading) return <div>Loading...</div>;

    return (
        <div>
            <h1>Payout Details</h1>
            {payout ? (
                <div>
                    <p>Provider ID: {payout.providerId}</p>
                    <p>Amount: {payout.amount}</p>
                    <p>Message: {payout.message}</p>
                    <p>Status: {payout.status}</p>
                    <p>Date: {new Date(payout.date).toLocaleString()}</p>
                </div>
            ) : (
                <p>Payout not found!</p>
            )}
        </div>
    );
};

export default PayoutDetails;