// src/components/PayoutForm.js
import React, { useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { createPayout, updatePayout, getPayoutById } from './PayoutApi';

const PayoutForm = () => {
    const [payout, setPayout] = useState({ providerId: '', amount: '', message: '', status: '' });
    const [isEdit, setIsEdit] = useState(false);
    const navigateTo = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            const fetchPayout = async () => {
                const data = await getPayoutById(id);
                setPayout(data);
                setIsEdit(true);
            };
            fetchPayout();
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayout({ ...payout, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isEdit) {
            await updatePayout(id, payout);
        } else {
            await createPayout(payout);
        }
        navigateTo('/');
    };

    return (
        <form onSubmit={handleSubmit}>
            <h1>{isEdit ? 'Edit Payout' : 'Add Payout'}</h1>
            <input type="number" name="providerId" value={payout.providerId} onChange={handleChange} placeholder="Provider ID" required />
            <input type="text" name="amount" value={payout.amount} onChange={handleChange} placeholder="Amount" required />
            <input type="number" name="message" value={payout.message} onChange={handleChange} placeholder="Message" required />
            <input type="text" name="status" value={payout.status} onChange={handleChange} placeholder="Status" required />
            <button type="submit">{isEdit ? 'Update' : 'Create'}</button>
        </form>
    );
};

export default PayoutForm;