import './style.css'
import { baseurl, successNotify } from '../../constants';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";

const EditProvider = () => {
  const location = useLocation();
  const row = location.state?.row;
  const [loader, showLoader, hideLoader] = useLoader();
  const navigateTo = useNavigate();

  // State to hold form values
  const [user, setUser] = useState({
    fullName: '',
    userName: '',
    city: '',
    aboutText: '',
    bank: '',
    ibanNumber: '',
    email: '',
    balance: '',
    profileImage: '',
    banarImage: '',
  });

  useEffect(() => {
    if (row) {
      setUser({
        fullName: row.fullName || '',
        userName: row.userName || '',
        city: row.city || '',
        aboutText: row.aboutText || '',
        bank: row.bank || '',
        ibanNumber: row.ibanNumber || '',
        email: row.email || '',
        balance: row.balance || '',
        profileImage: row.profileImage || '',
        banarImage: row.banarImage || '',
      });
    }
  }, [row]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  // Upload image function
  const uploadImage = async (file) => {
    const formdata = new FormData();
    formdata.append("image", file);
    try {
      const response = await fetch(baseurl + "image/upload", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
        },
        body: formdata,
      });
      const json = await response.json();
      return json.image;
    } catch (error) {
      console.error(error);
    }
  };

  // Handle image upload
  const handleImageUpload = async (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const imageName = await uploadImage(file);
      if (type === 'profile') {
        setUser({ ...user, profileImage: imageName });
      } else if (type === 'banner') {
        setUser({ ...user, banarImage: imageName });
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!row?.id) {
      console.error('No provider ID found');
      return;
    }
    
    try {
      showLoader();
      await axios.put(`${baseurl}provider/${row.id}`, user);
      successNotify();
      navigateTo("/admin/providers");
    } catch (error) {
      console.error('Error updating provider:', error);
    } finally {
      hideLoader();
    }
  };

  if (!row) {
    return (
      <div className="alert alert-danger m-4" role="alert">
        لم يتم العثور على بيانات المزود
      </div>
    );
  }

  return (
    <div className="provider-edit-container">
      <div className="page-header">
        <h2 className="page-title">تعديل مزود الخدمة</h2>
        <div className="breadcrumb">
          <i className="bx bx-home-alt"></i>
          <span>الرئيسية</span>
          <span>/</span>
          <span>مزودي الخدمة</span>
          <span>/</span>
          <span>تعديل</span>
        </div>
      </div>

      <div className="edit-card">
        <form onSubmit={handleSubmit} className="edit-form">
          <div className="form-grid">
            {/* Personal Information Section */}
            <div className="form-section">
              <h3 className="section-title">المعلومات الشخصية</h3>
              <div className="input-group">
                <label>الاسم الكامل</label>
                <input
                  type="text"
                  name="fullName"
                  value={user.fullName}
                  onChange={handleChange}
                  placeholder="أدخل الاسم الكامل"
                />
              </div>

              <div className="input-group">
                <label>رقم الهاتف</label>
                <input
                  type="tel"
                  name="userName"
                  value={user.userName}
                  onChange={handleChange}
                  placeholder="أدخل رقم الهاتف"
                />
              </div>

              <div className="input-group">
                <label>البريد الإلكتروني</label>
                <input
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                  placeholder="أدخل البريد الإلكتروني"
                />
              </div>

              <div className="input-group">
                <label>المدينة</label>
                <input
                  type="text"
                  name="city"
                  value={user.city}
                  onChange={handleChange}
                  placeholder="أدخل اسم المدينة"
                />
              </div>

              <div className="input-group">
                <label>نبذة عن المزود</label>
                <textarea
                  name="aboutText"
                  value={user.aboutText}
                  onChange={handleChange}
                  placeholder="أدخل نبذة عن المزود"
                  rows="4"
                />
              </div>
            </div>

            {/* Financial Information Section */}
            <div className="form-section">
              <h3 className="section-title">المعلومات المالية</h3>
              <div className="input-group">
                <label>البنك</label>
                <input
                  type="text"
                  name="bank"
                  value={user.bank}
                  onChange={handleChange}
                  placeholder="أدخل اسم البنك"
                />
              </div>

              <div className="input-group">
                <label>رقم الآيبان</label>
                <input
                  type="text"
                  name="ibanNumber"
                  value={user.ibanNumber}
                  onChange={handleChange}
                  placeholder="أدخل رقم الآيبان"
                />
              </div>

              <div className="input-group">
                <label>الرصيد</label>
                <input
                  type="number"
                  name="balance"
                  value={user.balance}
                  onChange={handleChange}
                  placeholder="أدخل الرصيد"
                />
              </div>

              {/* Images Section */}
              <div className="images-section">
                <div className="image-upload">
                  <label>الصورة الشخصية</label>
                  <div className="image-preview">
                    {user.profileImage && (
                      <img
                        src={`${baseurl}uploads/${user.profileImage}`}
                        alt="Profile"
                        className="preview-image"
                      />
                    )}
                    <div className="upload-overlay">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'profile')}
                        className="file-input"
                      />
                      <i className="fas fa-camera"></i>
                    </div>
                  </div>
                </div>

                <div className="image-upload">
                  <label>صورة البانر</label>
                  <div className="image-preview banner">
                    {user.banarImage && (
                      <img
                        src={`${baseurl}uploads/${user.banarImage}`}
                        alt="Banner"
                        className="preview-image"
                      />
                    )}
                    <div className="upload-overlay">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 'banner')}
                        className="file-input"
                      />
                      <i className="fas fa-camera"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-actions">
            <button type="button" className="btn-cancel" onClick={() => navigateTo('/admin/providers')}>
              إلغاء
            </button>
            <button type="submit" className="btn-save">
              حفظ التغييرات
            </button>
          </div>
        </form>
      </div>
      {loader}
    </div>
  );
};

export default EditProvider;
