import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ReactToPrint from 'react-to-print';

const TransactionList = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const componentRef = useRef();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('http://localhost:5139/api/transaction');
        setTransactions(response.data.items);
      } catch (err) {
        setError('خطأ في تحميل المعاملات');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const parseTransactionData = (dataStr) => {
    const data = {};
    const pairs = dataStr.split(',');

    pairs.forEach(pair => {
      const [key, value] = pair.split(/:(.+)/);
      if (key && value) {
        data[key.trim()] = value.trim().replace(/^"|"$/g, '');
      }
    });

    return data;
  };

  if (loading) return <div>جارٍ التحميل...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">قائمة المعاملات</h1>
      <ReactToPrint
        trigger={() => <button className="mb-4 bg-blue-500 text-white px-4 py-2 rounded">تصدير إلى PDF</button>}
        content={() => componentRef.current}
      />
      <div ref={componentRef}>
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 px-4 py-2">ID</th>
              <th className="border border-gray-300 px-4 py-2">رقم الدفع</th>
              <th className="border border-gray-300 px-4 py-2">النتيجة</th>
              <th className="border border-gray-300 px-4 py-2">المبلغ</th>
              <th className="border border-gray-300 px-4 py-2">PAN المخفي</th>
            </tr>
          </thead>
          <tbody>
            {transactions.length === 0 ? (
              <tr>
                <td className="border border-gray-300 px-4 py-2" colSpan="5">لا توجد معاملات متاحة</td>
              </tr>
            ) : (
              transactions.map((transaction) => {
                const transactionData = parseTransactionData(transaction.data);
                return (
                  <tr key={transaction.id}>
                    <td className="border border-gray-300 px-4 py-2">{transaction.id}</td>
                    <td className="border border-gray-300 px-4 py-2">{transactionData.PaymentId}</td>
                    <td className="border border-gray-300 px-4 py-2">{transactionData.Result}</td>
                    <td className="border border-gray-300 px-4 py-2">{transactionData.amount}</td>
                    <td className="border border-gray-300 px-4 py-2">{transactionData.maskedPAN}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <p>إجمالي المعاملات: {transactions.length}</p>
      </div>
    </div>
  );
};

export default TransactionList;