// Dialog.js
import React from 'react';

const Dialog = ({ isOpen, onClose,view }) => {
    if (!isOpen) return null; 
    return (
        <div className="fixed inset-0 flex items-center justify-center ">
            <div className="bg-white rounded-lg shadow-lg p-6 transform transition-transform duration-300 ease-in-out translate-y-0 opacity-100">
                {view}
                <button onClick={onClose} className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600">
                    الغاء
                </button>
            </div>
        </div>
    );
};
export default Dialog;