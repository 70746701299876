import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { baseurl, formateDate } from '../../constants';
import './Users.css';
import DeleteConfirmationModal from '../../Components/DeleteConfirmationModal/DeleteConfirmationModal';
import Pagination from '../../Components/Pagination/Pagination';

const UserList = () => {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(baseurl + `api/user?page=${pageNumber}`);
                setData(response.data.items);
                setFilteredData(response.data.items);
                setTotalPages(Math.ceil(response.data.total / 10));
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [pageNumber]);

    useEffect(() => {
        const filtered = data.filter(user => 
            user.fullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.userName?.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filtered);
    }, [searchQuery, data]);

    const initiateDelete = (user) => {
        setUserToDelete(user);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        if (!userToDelete) return;
        
        setDeleteLoading(true);
        try {
            await axios.delete(baseurl + `api/user/${userToDelete.id}`);
            setData(data.filter(item => item.id !== userToDelete.id));
            setFilteredData(filteredData.filter(item => item.id !== userToDelete.id));
            setShowDeleteModal(false);
            setUserToDelete(null);
        } catch (error) {
            console.error('Error deleting user:', error);
        } finally {
            setDeleteLoading(false);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        setPageNumber(newPage);
    };

    const LoadingSkeleton = () => (
        <div className="users-card">
            {[1, 2, 3, 4, 5].map(i => (
                <div key={i} className="skeleton" style={{ height: '60px', margin: '8px' }}></div>
            ))}
        </div>
    );

    return (
        <div className="users-container">
            <div className="users-header">
                <h1 className="users-title">قائمة المستخدمين</h1>
                <div className="search-container">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="البحث عن مستخدم..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <i className="bi bi-search search-icon"></i>
                </div>
            </div>

            {isLoading ? (
                <LoadingSkeleton />
            ) : (
                <>
                    <div className="users-grid">
                        {filteredData.map((item, index) => (
                            <div key={item.id} className="user-card" onClick={() => navigate(`/admin/user/detail/${item.id}`)}>
                                <div className="user-card-header">
                                    {item.profileImage ? (
                                        <img
                                            src={baseurl + "/uploads/" + item.profileImage}
                                            className="user-image"
                                            alt={item.fullName}
                                        />
                                    ) : (
                                        <div className="user-image-placeholder">
                                            <i className="bi bi-person"></i>
                                        </div>
                                    )}
                                    <div className="user-info">
                                        <h3 className="user-name">{item.fullName}</h3>
                                        <p className="user-email">{item.email}</p>
                                    </div>
                                </div>

                                <div className="user-details">
                                    <div className="user-detail-item">
                                        <i className="bi bi-phone"></i>
                                        <span>{item.userName}</span>
                                    </div>
                                    <div className="user-detail-item">
                                        <i className="bi bi-calendar"></i>
                                        <span>{formateDate(item.createdAt)}</span>
                                    </div>
                                </div>

                                <div className="action-buttons" onClick={e => e.stopPropagation()}>
                                    <Link to={"/admin/user/edit/" + item.id} className="btn-edit">
                                        <i className="bi bi-pencil"></i>
                                        تعديل
                                    </Link>
                                    <button
                                        className="btn-delete"
                                        onClick={() => initiateDelete(item)}
                                    >
                                        <i className="bi bi-trash"></i>
                                        حذف
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {!isLoading && totalPages > 0 && (
                        <Pagination 
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                        />
                    )}
                </>
            )}
            <DeleteConfirmationModal 
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleDelete}
                itemName={userToDelete?.fullName}
                itemType="المستخدم"
                loading={deleteLoading}
            />
        </div>
    );
};

export default UserList;