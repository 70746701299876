import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SideMenu.css';

const menuConfig = [
  {
    id: 'home',
    label: 'الرئيسية',
    path: '/admin/home',
    icon: 'bi-house-door'
  },
  {
    id: 'chats',
    label: 'المحادثات',
    path: '/admin/chats',
    icon: 'bi-chat-dots'
  },
  {
    id: 'reports',
    label: 'التقارير',
    icon: 'bi-graph-up',
    children: [
      { id: 'services-report', label: 'تقرير الطلعات', path: '/admin/reports/services', icon: 'bi-basket' },
      { id: 'bookings-report', label: 'تقرير الحجوزات', path: '/admin/reports/bookings', icon: 'bi-calendar-check' },
      { id: 'users-report', label: 'تقرير المستخدمين', path: '/admin/reports/users', icon: 'bi-people' }
    ]
  },
  {
    id: 'users',
    label: 'المستخدمين',
    icon: 'bi-people',
    children: [
      { id: 'users-list', label: 'قائمة المستخدمين', path: '/admin/user', icon: 'bi-list-ul' }
    ]
  },
  {
    id: 'providers',
    label: 'مزودي الخدمة',
    icon: 'bi-person-badge',
    children: [
      { id: 'providers-list', label: 'قائمة المزودين', path: '/admin/providers', icon: 'bi-list-ul' },
      { id: 'providers-payouts', label: 'دفعات المزودين', path: '/admin/payouts', icon: 'bi-cash' }
    ]
  },
  {
    id: 'categories',
    label: 'الفئات',
    icon: 'bi-grid',
    children: [
      { id: 'categories-list', label: 'قائمة الفئات', path: '/admin/category', icon: 'bi-list-ul' }
    ]
  },
  {
    id: 'services',
    label: 'الطلعات',
    icon: 'bi-basket',
    children: [
      { id: 'services-list', label: 'قائمة الطلعات', path: '/admin/service', icon: 'bi-list-ul' },
      { id: 'bookings', label: 'قائمة الحجوزات', path: '/admin/booking', icon: 'bi-calendar-check' }
    ]
  },
  {
    id: 'trips',
    label: 'الطلعات الخاصة',
    icon: 'bi-compass',
    children: [
      { id: 'trips-list', label: 'قائمة الطلعات', path: '/admin/trip', icon: 'bi-list-ul' }
    ]
  },
  {
    id: 'transactions',
    label: 'المعاملات المالية',
    icon: 'bi-cash-stack',
    children: [
      { id: 'transactions-list', label: 'المعاملات المالية', path: '/admin/transactions', icon: 'bi-list-ul' }
    ]
  },
  {
    id: 'menu',
    label: 'المنيو',
    icon: 'bi-list-check',
    children: [
      { id: 'menu-list', label: 'قائمة المنيو', path: '/admin/menu', icon: 'bi-list-ul' },
      { id: 'menu-categories', label: 'الأقسام', path: '/admin/menucategory', icon: 'bi-grid' },
      { id: 'menu-orders', label: 'الطلبات', path: '/admin/order', icon: 'bi-bag-check' }
    ]
  }
];

const MenuItem = ({ item, isActive, isOpen, onClick, onSubItemClick }) => {
  const hasChildren = item.children && item.children.length > 0;
  
  return (
    <div className={`menu-item ${isActive ? 'active' : ''}`}>
      <div 
        className={`menu-item-header ${hasChildren ? 'has-children' : ''}`} 
        onClick={() => onClick(item)}
      >
        <i className={`bi ${item.icon}`}></i>
        <span className="menu-label">{item.label}</span>
        {hasChildren && (
          <i className={`bi ${isOpen ? 'bi-chevron-down' : 'bi-chevron-left'} arrow-icon`}></i>
        )}
      </div>
      
      {hasChildren && isOpen && (
        <div className="submenu">
          {item.children.map(child => (
            <div
              key={child.id}
              className={`submenu-item ${isActive && child.path === window.location.pathname ? 'active' : ''}`}
              onClick={() => onSubItemClick(child)}
            >
              <i className={`bi ${child.icon}`}></i>
              <span>{child.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [openMenus, setOpenMenus] = useState(new Set());

  const handleMenuClick = (item) => {
    if (item.children) {
      setOpenMenus(prev => {
        const newSet = new Set();
        if (!prev.has(item.id)) {
          newSet.add(item.id);
        }
        return newSet;
      });
    } else if (item.path) {
      navigate(item.path);
    }
  };

  const handleSubItemClick = (item) => {
    if (item.path) {
      navigate(item.path);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const isMenuActive = (item) => {
    if (item.path === location.pathname) return true;
    if (item.children) {
      return item.children.some(child => child.path === location.pathname);
    }
    return false;
  };

  return (
    <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <img src="/images/logo.png" alt="طلعات" className="logo" />
        <button 
          className="collapse-btn"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <i className={`bi ${isCollapsed ? 'bi-chevron-right' : 'bi-chevron-left'}`}></i>
        </button>
      </div>

      <div className="menu-container">
        {menuConfig.map(item => (
          <MenuItem
            key={item.id}
            item={item}
            isActive={isMenuActive(item)}
            isOpen={openMenus.has(item.id)}
            onClick={handleMenuClick}
            onSubItemClick={handleSubItemClick}
          />
        ))}
      </div>

      <div className="sidebar-footer">
        <div className="logout-button" onClick={handleLogout}>
          <i className="bi bi-box-arrow-right"></i>
          <span>تسجيل الخروج</span>
        </div>
      </div>
    </div>
  );
}
