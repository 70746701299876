import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { baseurl } from '../../constants';
import Services from './Services';
import './ProviderDetail.css';

const ProviderDetail = () => {
  const location = useLocation();
  const [provider, setProvider] = useState(location.state?.user || null);
  const [loading, setLoading] = useState(!provider);

  useEffect(() => {
    if (!provider && location.state?.user?.id) {
      fetchProviderDetails(location.state.user.id);
    }
  }, [provider, location.state]);

  const fetchProviderDetails = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(baseurl + `admin/providers/${id}`);
      setProvider(response.data);
    } catch (error) {
      console.error('Error fetching provider details:', error);
    } finally {
      setLoading(false);
    }
  };

  const getBalanceClass = (balance) => {
    const value = parseFloat(balance);
    if (value > 0) return 'balance-positive';
    if (value < 0) return 'balance-negative';
    return 'balance-zero';
  };

  if (loading) {
    return (
      <div className="provider-detail-container">
        <div className="loading-container">
          <div className="loading-spinner" />
        </div>
      </div>
    );
  }

  if (!provider) {
    return (
      <div className="provider-detail-container">
        <div className="error-container">
          <h3>خطأ</h3>
          <p>لم يتم العثور على مزود الخدمة</p>
        </div>
      </div>
    );
  }

  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString('ar-SA', options);
  };

  return (
    <div className="provider-detail-container">
      <div className="provider-header">
        <h1 className="header-title">تفاصيل مزود الخدمة</h1>
        <div className="header-breadcrumb">
          <i className="bx bx-home-alt"></i>
          <span>الرئيسية</span>
          <i className="bx bx-chevron-left"></i>
          <span>مزودي الخدمة</span>
          <i className="bx bx-chevron-left"></i>
          <span>تفاصيل</span>
        </div>
      </div>

      <div className="provider-card">
        <div className="card-header">
          <h2>المعلومات الشخصية</h2>
        </div>
        <div className="card-body">
          <div className="info-grid">
            <div className="info-section">
              <div className="info-group">
                <label className="info-label">الاسم الكامل</label>
                <div className="info-value">{provider.fullName || 'غير متوفر'}</div>
              </div>

              <div className="info-group">
                <label className="info-label">رقم الهاتف</label>
                <div className="info-value">{provider.userName || 'غير متوفر'}</div>
              </div>

              <div className="info-group">
                <label className="info-label">البريد الإلكتروني</label>
                <div className="info-value">{provider.email || 'غير متوفر'}</div>
              </div>

              <div className="info-group">
                <label className="info-label">المدينة</label>
                <div className="info-value">{provider.city || 'غير متوفر'}</div>
              </div>

              <div className="info-group">
                <label className="info-label">نبذة عن المزود</label>
                <div className="info-value about">{provider.aboutText || 'لا يوجد وصف متوفر'}</div>
              </div>
            </div>

            <div className="info-section">
              <div className="profile-image-section">
                <div className="profile-image-container">
                  {provider.profileImage ? (
                    <img
                      src={baseurl + "uploads/" + provider.profileImage}
                      alt={provider.fullName}
                      className="profile-image"
                    />
                  ) : (
                    <div className="profile-image-placeholder">
                      <i className="bx bx-user"></i>
                    </div>
                  )}
                </div>

                <div className={`balance-section ${getBalanceClass(provider.balance)}`}>
                  <div className="balance-label">الرصيد الحالي</div>
                  <div className="balance-value">
                    {parseFloat(provider.balance || 0).toLocaleString('ar-SA')} ريال
                  </div>
                </div>
              </div>

              <div className="info-group">
                <label className="info-label">البنك</label>
                <div className="info-value">{provider.bank || 'غير متوفر'}</div>
              </div>

              <div className="info-group">
                <label className="info-label">رقم الآيبان</label>
                <div className="info-value" dir="ltr">{provider.ibanNumber || 'غير متوفر'}</div>
              </div>

              <div className="info-group">
                <label className="info-label">تاريخ الانضمام</label>
                <div className="info-value">{provider.createdAt ? formatDate(provider.createdAt) : 'غير متوفر'}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="services-section">
        <Services id={provider.id} />
      </div>
    </div>
  );
};

export default ProviderDetail;