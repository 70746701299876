import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { baseurl } from '../../constants';
import './UserDetails.css';

const UserDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/user/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };

    return (
        <div className="user-detail-container">
            <div className="user-detail-header">
                <h1 className="user-detail-title">تفاصيل المستخدم</h1>
                <p className="user-detail-subtitle">عرض معلومات المستخدم الكاملة</p>
            </div>

            <div className="user-detail-card">
                <div className="user-detail-profile">
                    {item.profileImage ? (
                        <img
                            src={baseurl + "/uploads/" + item.profileImage}
                            alt={item.fullName}
                            className="user-detail-avatar"
                        />
                    ) : (
                        <div className="user-detail-avatar">
                            <i className="bi bi-person" style={{ fontSize: '3rem' }}></i>
                        </div>
                    )}
                    <div className="user-detail-info">
                        <h2 className="user-detail-name">{item.fullName}</h2>
                        <p className="user-detail-email">{item.email}</p>
                        <div className="user-detail-stats">
                            <div className="user-detail-stat">
                                <div className="user-detail-stat-value">{item.city || 'غير محدد'}</div>
                                <div className="user-detail-stat-label">المدينة</div>
                            </div>
                            <div className="user-detail-stat">
                                <div className="user-detail-stat-value">{formatDate(item.createdAt)}</div>
                                <div className="user-detail-stat-label">تاريخ التسجيل</div>
                            </div>
                            <div className="user-detail-stat">
                                <div className="user-detail-stat-value">{item.userName || 'غير محدد'}</div>
                                <div className="user-detail-stat-label">رقم الهاتف</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="user-detail-content">
                    <div className="user-detail-section">
                        <h3 className="user-detail-section-title">معلومات أساسية</h3>
                        <div className="user-detail-field">
                            <label className="user-detail-label">نبذة عن المستخدم</label>
                            <div className="user-detail-value">{item.aboutText || 'لا يوجد نبذة'}</div>
                        </div>
                        <div className="user-detail-field">
                            <label className="user-detail-label">العنوان</label>
                            <div className="user-detail-value">{item.descAddress || 'غير محدد'}</div>
                        </div>
                    </div>

                    {item.role && (
                        <div className="user-detail-section">
                            <h3 className="user-detail-section-title">معلومات إضافية</h3>
                            <div className="user-detail-field">
                                <label className="user-detail-label">الدور</label>
                                <div className="user-detail-value">{item.role}</div>
                            </div>
                            <div className="user-detail-field">
                                <label className="user-detail-label">الحالة</label>
                                <div className="user-detail-value">{item.statuse || 'نشط'}</div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="user-detail-actions">
                    <Link to={`/admin/user/edit/${id}`} className="user-detail-button primary">
                        <i className="bi bi-pencil"></i>
                        تعديل المعلومات
                    </Link>
                    <Link to="/admin/user" className="user-detail-button secondary">
                        <i className="bi bi-arrow-right"></i>
                        العودة للقائمة
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default UserDetail;