import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { baseurl, formateDate } from "../../constants";
import "./Services.css";

export default function Services({ id }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const formdata = new FormData();
    formdata.append("id", id);
    try {
      setLoading(true);
      const response = await fetch(baseurl + "provider/get-services", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formdata,
      });
      const json = await response.json();
      setData(json);
      return json;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return (
      <div className="services-loading">
        <div className="loading-spinner" />
      </div>
    );
  }

  return (
    <div className="services-container">
      <div className="services-header">
        <h2 className="services-title">الرحلات</h2>
      </div>

      {data && data.length > 0 ? (
        <div className="services-grid">
          {data.map((service, index) => (
            <div key={service.id || index} className="service-card">
              <div className="service-image">
                <img
                  src={baseurl + "uploads/" + service.image}
                  alt={service.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "https://via.placeholder.com/300x200?text=No+Image";
                  }}
                />
              </div>
              <div className="service-content">
                <h3 className="service-name">{service.name}</h3>
                <div className="service-details">
                  <div className="detail-item">
                    <i className="bx bx-map"></i>
                    <span>{service.city}</span>
                  </div>
                  <div className="detail-item">
                    <i className="bx bx-money"></i>
                    <span>{service.price} ريال</span>
                  </div>
                  <div className="detail-item">
                    <i className="bx bx-calendar"></i>
                    <span>{formateDate(service.startTime)}</span>
                  </div>
                </div>
                <div className="service-actions">
                  <Link
                     to={`/admin/service/detail/${service.id}`} 
                     state={{ row: service }}
                    className="view-button"
                  >
                    <i className="bx bx-show"></i>
                    <span>عرض التفاصيل</span>
                  </Link>
                  <Link
                                         to={`/admin/service/edit/${service.id}`} 
                                         state={{ row: service }}
                    className="edit-button"
                  >
                    <i className="bx bx-edit"></i>
                    <span>تعديل</span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-services">
          <i className="bx bx-package"></i>
          <p>لا توجد رحلات متاحة</p>
        </div>
      )}
    </div>
  );
}