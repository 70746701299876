import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { baseurl } from '../../constants';

const CategoryDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchItem = async () => {
            setLoading(true);
            try {
                const response = await axios.get(baseurl + `api/category/${id}`);
                setItem(response.data.item);
            } catch (error) {
                console.error('Error fetching category:', error);
            }
            setLoading(false);
        };
        fetchItem();
    }, [id]);

    if (loading) {
        return (
            <div className="container-fluid p-4">
                <div className="text-center py-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">جاري التحميل...</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid p-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="m-0">تفاصيل الفئة</h3>
                <div className="d-flex gap-2">
                    <Link to={`/admin/category/edit/${id}`} className="btn btn-primary">
                        <i className="fas fa-edit ms-1"></i>
                        تعديل الفئة
                    </Link>
                    <Link to="/admin/category" className="btn btn-outline-secondary">
                        <i className="fas fa-arrow-right ms-1"></i>
                        العودة للقائمة
                    </Link>
                </div>
            </div>

            <div className="row g-4">
                {/* Main Info Card */}
                <div className="col-md-8">
                    <div className="card shadow-sm h-100">
                        <div className="card-header bg-transparent">
                            <h5 className="card-title m-0">المعلومات الأساسية</h5>
                        </div>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label text-muted">رقم الفئة</label>
                                        <div className="fw-bold">#{item.id}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label className="form-label text-muted">الاسم</label>
                                        <div className="fw-bold">{item.name}</div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label text-muted">الوصف</label>
                                        <div>{item.desc || 'لا يوجد وصف'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Image Card */}
                <div className="col-md-4">
                    <div className="card shadow-sm">
                        <div className="card-header bg-transparent">
                            <h5 className="card-title m-0">الصورة</h5>
                        </div>
                        <div className="card-body text-center">
                            {item.image ? (
                                <img
                                    src={baseurl + "uploads/" + item.image}
                                    alt={item.name}
                                    className="img-fluid rounded"
                                    style={{ maxHeight: '200px', objectFit: 'cover' }}
                                />
                            ) : (
                                <div className="text-muted">لا توجد صورة</div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Additional Info Card */}
                <div className="col-12">
                    <div className="card shadow-sm">
                        <div className="card-header bg-transparent">
                            <h5 className="card-title m-0">معلومات إضافية</h5>
                        </div>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label text-muted">تاريخ الإنشاء</label>
                                        <div>{item.createdAt}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label text-muted">الاسم بالإنجليزية</label>
                                        <div>{item.name_en || 'غير متوفر'}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label text-muted">الوصف بالإنجليزية</label>
                                        <div>{item.desc_en || 'غير متوفر'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryDetail;