import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { baseurl, errorNotify, successNotify } from "../constants";
import { Toaster } from "react-hot-toast";
import useLoader from "../Components/loader/useLoader";
import './Login.css';

export default function Login() {
  const [formData, setFormData] = useState({
    userName: '',
    password: ''
  });
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigateTo = useNavigate();
  const [loader, showLoader, hideLoader] = useLoader();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setErrors('');
  };

  const login = async (e) => {
    e.preventDefault();
    if (!formData.userName || !formData.password) {
      setErrors(!formData.userName ? "الرجاء إدخال اسم المستخدم" : "الرجاء إدخال كلمة المرور");
      return;
    }

    setIsLoading(true);
    showLoader();
    const form = new FormData();
    form.append("userName", formData.userName);
    form.append("password", formData.password);

    try {
      const response = await fetch(baseurl + "auth/admin-login", {
        method: "POST",
        referrerPolicy: "unsafe-url",
        body: form
      });
      const json = await response.json();
      hideLoader();
      setIsLoading(false);

      if (json.token) {
        localStorage.setItem("token", json.token);
        localStorage.setItem("id", json.user.id);
        successNotify("تم تسجيل الدخول بنجاح");
        navigateTo("/admin");
      } else {
        setErrors("اسم المستخدم أو كلمة المرور غير صحيحة");
      }
    } catch (error) {
      hideLoader();
      setIsLoading(false);
      errorNotify("حدث خطأ في تسجيل الدخول");
    }
  };

  return (
    <div className="login-container">
      {loader}
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            style: {
              background: '#10B981',
              color: "white",
              padding: '16px',
              borderRadius: '12px',
              fontSize: '14px'
            },
          },
          error: {
            style: {
              background: '#EF4444',
              color: "white",
              padding: '16px',
              borderRadius: '12px',
              fontSize: '14px'
            },
          },
        }}
      />

      <div className="login-content">
        <div className="login-left-side">
          <div className="login-image-overlay"></div>
          <div className="login-text-overlay">
            <div className="brand-logo">
              <img src="./images/logo.png" alt="طلعات" className="logo-image object-contain" />
            </div>
            <h1>طلعات</h1>
            <p>نظام إدارة الرحلات والحجوزات</p>
          </div>
        </div>

        <div className="login-right-side">
          <div className="login-form-wrapper">
            <div className="login-header">
              <div className="welcome-text">
                <h2>مرحباً بعودتك! 👋</h2>
                <p>سجل دخولك للوصول إلى لوحة التحكم</p>
              </div>
            </div>

            {errors && (
              <div className="error-message">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="error-icon">
                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                </svg>
                <span>{errors}</span>
              </div>
            )}

            <form onSubmit={login} className="login-form">
              <div className="form-fields">
                <div className="form-group">
                  <label htmlFor="userName">اسم المستخدم</label>
                  <div className="input-group">
                    <input
                      id="userName"
                      name="userName"
                      type="text"
                      placeholder="أدخل اسم المستخدم"
                      value={formData.userName}
                      onChange={handleInputChange}
                      className={errors && !formData.userName ? 'error' : ''}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="input-icon">
                      <path fillRule="evenodd" d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z" clipRule="evenodd" />
                    </svg>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="password">كلمة المرور</label>
                  <div className="input-group">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="أدخل كلمة المرور"
                      value={formData.password}
                      onChange={handleInputChange}
                      className={errors && !formData.password ? 'error' : ''}
                    />
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="input-icon">
                      <path fillRule="evenodd" d="M12 1.5a5.25 5.25 0 00-5.25 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z" clipRule="evenodd" />
                    </svg>
                  </div>
                </div>
              </div>

              <button 
                type="submit" 
                className="submit-button"
                disabled={isLoading}
              >
                <span>{isLoading ? 'جاري تسجيل الدخول...' : 'تسجيل الدخول'}</span>
                {!isLoading && (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="button-icon">
                    <path fillRule="evenodd" d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z" clipRule="evenodd" />
                  </svg>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
