import { useState } from "react";
import Dialog from "../../Components/Dialog";

export default function ProviderWalet(){
    const [isOpen, setIsOpen] = useState(false);

    const openDialog = () => {
        setIsOpen(true);
    };

    const closeDialog = () => {
        setIsOpen(false);
    };
    
    const dialog= (

        <>
                <h2 className="text-xl font-semibold mb-4">تحويل </h2>
                <input placeholder={"المبلغ"}  className="w-full px-3 border mt-3 h-[60px] text-[15px] rounded-md"/>
                <input placeholder={"الرسالة"}  className="w-full px-3 border mt-3 h-[60px] text-[15px] rounded-md"/>
                <button className="w-full text-[#ffffff] bg-[green] my-3 h-[60px] text-[15px] rounded-md"><span>تأكيد التحويل</span></button>
        </>

    )
    return <>

     <Dialog isOpen={isOpen} onClose={closeDialog} view={dialog} />
   <div class="bg-white shadow-sm rounded-lg p-6 max-w-full h-[100vh]">
      <div className="text-lg">
        <i className="fas fa-wallet p-2"></i>
        <i class="fas fa-arrow-left p-2"></i>
        <span >المعاملات المالية</span>

        <div>
        <div className="flex justify-between">
         <div>
         <p className="text-grey my-3">الرصيد الإجمالي</p>
         <p className="text-bold text-black text-[30px]">1500 ريال</p>
         </div>

         <button onClick={openDialog} className="px-3 text-[#ffffff] bg-[green] h-[60px] text-center rounded"><span>دفع</span><i class="fas fa-credit-card px-3"></i></button>
        </div>
        <div class="overflow-x-auto my-3">
            <table class="mt-[50px] text-right min-w-full bg-white border text-right border-gray-300 rounded-lg shadow-lg">
                <thead>
                    <tr class="bg-gray-200 text-gray-600">
                    <th class="py-3 px-4 ">ID</th>
                    <th class="py-3 px-4 ">الرقم</th>
                    <th class="py-3 px-4 ">المعاملة</th>
                    <th class="py-3 px-4 ">النوع</th>
                    <th class="py-3 px-4 ">التاريخ</th>
                    <th class="py-3 px-4 ">المبلغ</th>
                    <th class="py-3 px-4 ">الحالة</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="hover:bg-gray-100">
                    <td class="py-2 px-4 border-b">#13</td>
                    <td class="py-2 px-4 border-b">4576347</td>
                    <td class="py-2 px-4 border-b">طلب رقم 56</td>
                    <td class="py-2 px-4 border-b">إيداع</td>
                    <td class="py-2 px-4 border-b">13-05-2024</td>
                    <td class="py-2 px-4 border-b text-green-500">1000</td>
                    <td class="py-2 px-4 border-b text-green-500">مكتمل</td>
                    </tr>

                    <tr class="hover:bg-gray-100">
                    <td class="py-2 px-4 border-b">#13</td>
                    <td class="py-2 px-4 border-b">4576347</td>
                    <td class="py-2 px-4 border-b">طلب رقم 56</td>
                    <td class="py-2 px-4 border-b">إيداع</td>
                    <td class="py-2 px-4 border-b">13-05-2024</td>
                    <td class="py-2 px-4 border-b text-green-500">1000</td>
                    <td class="py-2 px-4 border-b text-green-500">مكتمل</td>
                    </tr>

                    <tr class="hover:bg-gray-100">
                    <td class="py-2 px-4 border-b">#13</td>
                    <td class="py-2 px-4 border-b">4576347</td>
                    <td class="py-2 px-4 border-b">طلب رقم 56</td>
                    <td class="py-2 px-4 border-b">إيداع</td>
                    <td class="py-2 px-4 border-b">13-05-2024</td>
                    <td class="py-2 px-4 border-b text-green-500">1000</td>
                    <td class="py-2 px-4 border-b text-green-500">مكتمل</td>
                    </tr>
                </tbody>
            </table>
        </div>

        </div>
      </div>
    </div>
    </>
}