import React from 'react';
import './DeleteConfirmationModal.css';

const DeleteConfirmationModal = ({ 
    isOpen, 
    onClose, 
    onConfirm, 
    itemName,
    itemType = 'العنصر',
    loading = false 
}) => {
    if (!isOpen) return null;

    return (
        <div className="delete-modal-overlay" onClick={onClose}>
            <div className="delete-modal" onClick={e => e.stopPropagation()}>
                <div className="delete-modal-content">
                    <div className="delete-modal-icon">
                        <i className="fas fa-exclamation-triangle"></i>
                    </div>
                    <h3 className="delete-modal-title">تأكيد الحذف</h3>
                    <p className="delete-modal-message">
                        هل أنت متأكد من حذف {itemType}{" "}
                        <span className="item-name">
                            {itemName}
                        </span>؟
                    </p>
                    <p className="delete-modal-warning">
                        لا يمكن التراجع عن هذا الإجراء.
                    </p>
                    <div className="delete-modal-actions">
                        <button 
                            className="btn-cancel" 
                            onClick={onClose}
                            disabled={loading}
                        >
                            إلغاء
                        </button>
                        <button 
                            className="btn-delete" 
                            onClick={onConfirm}
                            disabled={loading}
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            ) : (
                                <i className="fas fa-trash-alt me-2"></i>
                            )}
                            تأكيد الحذف
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirmationModal;
