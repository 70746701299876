import { useState, useEffect } from "react";
import Dialog from "../../Components/Dialog";
import html2pdf from 'html2pdf.js';
import { baseurl } from "../../constants";
import JsBarcode from "jsbarcode";

const cairoFontBase64 = '...'; // Base64 encoded Cairo font

export default function PayouList() {
    const [isOpen, setIsOpen] = useState(false);
    const [payouts, setPayouts] = useState([]);
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState("");
    const [amount, setAmount] = useState("");
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [total, setTotal] = useState(0);

    const openDialog = () => {
        setIsOpen(true);
        fetchProviders(); // Fetch providers when dialog opens
    };

    const closeDialog = () => {
        setIsOpen(false);
        resetForm(); // Reset form fields when closing
    };

    const resetForm = () => {
        setSelectedProvider("");
        setAmount("");
        setMessage("");
    };

    const fetchProviders = async () => {
        try {
            const response = await fetch(baseurl+"/user/get-all-providers", {
                method: "POST",
            });
            if (!response.ok) throw new Error("Failed to fetch providers");
            const data = await response.json();
            setProviders(data); // Set providers from the response
        } catch (error) {
            console.error('Error fetching providers:', error);
        }
    };

    const addPayout = async () => {
        const formData = new FormData();
        formData.append('ProviderId', selectedProvider);
        formData.append('Amount', amount);
        formData.append('Message', message);
        try {
            const response = await fetch(baseurl+"/payouts/add", {
                method: "POST",
                body: formData,
            });
            if (!response.ok) throw new Error("Failed to add payout");
            closeDialog(); // Close the dialog on success
            fetchPayouts(); // Refresh payouts list
        } catch (error) {
            console.error('Error adding payout:', error);
        }
    };

    const fetchPayouts = async () => {
        const formData = new FormData();
        formData.append('page', page);
        formData.append('pageSize', pageSize);
        if (fromDate) formData.append('FromDate', new Date(fromDate).toISOString());
        if (toDate) formData.append('ToDate', new Date(toDate).toISOString());

        try {
            const response = await fetch(baseurl+"payouts", {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const totalCountHeader = response.headers.get("X-Total-Count");
            setPayouts(data.payouts);
            setTotalCount(totalCountHeader);
            setTotalAmount(data.totalAmount);
            setTotal(data.total);
        } catch (error) {
            console.error('Error fetching payouts:', error);
        }
    };

    const handleApplyFilters = () => {
        fetchPayouts();
    };

    const handleResetFilters = () => {
        setFromDate("");
        setToDate("");
        setPage(1);
        fetchPayouts();
    };

    useEffect(() => {
        fetchPayouts();
    }, [page]);

    const printInvoice = (payout) => {
        // إنشاء عنصر HTML للفاتورة
        const invoiceElement = document.createElement('div');
        
        // إنشاء الباركود
        const barcodeCanvas = document.createElement('canvas');
        JsBarcode(barcodeCanvas, `INV-${payout.id}`, {
            format: "CODE128",
            width: 2,
            height: 50,
            displayValue: true
        });

        // تحديد محتوى الفاتورة
        invoiceElement.innerHTML = `
            <div style="font-family: 'Tajawal', sans-serif; direction: rtl; padding: 20px; max-width: 800px; margin: 0 auto; background: #fff;">
                <!-- رأس الفاتورة -->
                <div style="text-align: center; margin-bottom: 30px;">
                    <img src="../images/logo.png" style="width: 150px; margin-bottom: 20px;" />
                    <h1 style="color: #2c3e50; font-size: 28px; margin: 0;">فاتورة دفع</h1>
                </div>

                <!-- معلومات الفاتورة -->
                <div style="margin-bottom: 30px;">
                    <p style="color: #34495e; font-size: 16px; margin: 5px 0;">
                        <strong>رقم الفاتورة:</strong> ${payout.id}
                    </p>
                    <p style="color: #34495e; font-size: 16px; margin: 5px 0;">
                        <strong>التاريخ:</strong> ${new Date(payout.date).toLocaleDateString('ar-SA')}
                    </p>
                </div>

                <!-- معلومات المزود -->
                <div style="background: #f8f9fa; padding: 20px; border-radius: 8px; margin-bottom: 30px;">
                    <h2 style="color: #2c3e50; font-size: 20px; margin: 0 0 15px 0;">معلومات المزود</h2>
                    <p style="color: #34495e; font-size: 16px; margin: 5px 0;">
                        <strong>الاسم:</strong> ${payout.providerName}
                    </p>
                    <p style="color: #34495e; font-size: 16px; margin: 5px 0;">
                        <strong>رقم الهاتف:</strong> ${payout.providerPhone}
                    </p>
                </div>

                <!-- تفاصيل الدفع -->
                <div style="background: #f8f9fa; padding: 20px; border-radius: 8px; margin-bottom: 30px;">
                    <h2 style="color: #2c3e50; font-size: 20px; margin: 0 0 15px 0;">تفاصيل الدفع</h2>
                    <p style="color: #34495e; font-size: 16px; margin: 5px 0;">
                        <strong>الرسالة:</strong> ${payout.message}
                    </p>
                    <div style="background: #2ecc71; color: white; padding: 10px 20px; border-radius: 5px; display: inline-block; margin-top: 15px;">
                        <strong>المبلغ:</strong> ${payout.amount} ريال
                    </div>
                </div>

                <!-- الباركود -->
                <div style="text-align: center; margin-bottom: 30px;">
                    <img src="${barcodeCanvas.toDataURL()}" style="max-width: 300px;" />
                </div>

                <!-- التذييل -->
                <div style="text-align: center; color: #95a5a6; font-size: 14px; margin-top: 50px;">
                    <p style="margin: 5px 0;">شكراً لتعاملكم مع طلعات</p>
                    <p style="margin: 5px 0;">هذه الفاتورة تم إنشاؤها إلكترونياً</p>
                </div>
            </div>
        `;

        // إضافة خط Tajawal
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/css2?family=Tajawal:wght@400;700&display=swap';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        // تحويل HTML إلى PDF
        const opt = {
            margin: 10,
            filename: `فاتورة_${payout.id}.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { 
                scale: 2,
                useCORS: true,
                logging: false
            },
            jsPDF: { 
                unit: 'mm', 
                format: 'a4', 
                orientation: 'portrait'
            }
        };

        // إنتظار تحميل الخط قبل إنشاء PDF
        setTimeout(() => {
            html2pdf().set(opt).from(invoiceElement).save();
        }, 1000);
    };

    const dialog = (
        <>
            <h2 className="text-xl font-semibold mb-4">إضافة تحويل</h2>
            <select
                value={selectedProvider}
                onChange={(e) => setSelectedProvider(e.target.value)}
                className="w-full px-3 border mt-3 h-[60px] text-[15px] rounded-md"
            >
                <option value="">اختر مزودًا</option>
                {providers.map(provider => (
                    <option key={provider.id} value={provider.id}>{provider.fullName}</option>
                ))}
            </select>
            
            <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder={"المبلغ"}
                className="w-full px-3 border mt-3 h-[60px] text-[15px] rounded-md"
            />
            <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={"الرسالة"}
                className="w-full px-3 border mt-3 h-[60px] text-[15px] rounded-md"
            />
            <button onClick={addPayout} className="w-full text-[#ffffff] bg-[green] my-3 h-[60px] text-[15px] rounded-md">
                <span>تأكيد التحويل</span>
            </button>
        </>
    );

    return (
        <>
            <Dialog isOpen={isOpen} onClose={closeDialog} view={dialog} />
            <div className="bg-gradient-to-r from-white to-gray-50 rounded-xl shadow-lg p-8 max-w-full min-h-screen">
                <div className="space-y-6">
                    {/* Header Section */}
                    <div className="flex items-center space-x-3 mb-8">
                        <div className="bg-green-100 p-3 rounded-full">
                            <i className="fas fa-wallet text-green-600 text-xl"></i>
                        </div>
                        <h1 className="text-2xl font-bold text-gray-800">المعاملات المالية</h1>
                    </div>

                    {/* Stats Card */}
                    <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                        <p className="text-gray-600 text-sm mb-2">الرصيد الإجمالي</p>
                        <p className="text-3xl font-bold text-gray-800">{total} <span className="text-lg text-gray-600">ريال</span></p>
                    </div>

                    {/* Filter Section */}
                    <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                        <div className="flex flex-wrap gap-4">
                            <input 
                                type="date" 
                                value={fromDate} 
                                onChange={(e) => setFromDate(e.target.value)} 
                                className="flex-1 px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all" 
                            />
                            <input 
                                type="date" 
                                value={toDate} 
                                onChange={(e) => setToDate(e.target.value)} 
                                className="flex-1 px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all" 
                            />
                            <button 
                                onClick={handleApplyFilters} 
                                className="px-6 py-3 bg-green-600 hover:bg-green-700 text-white rounded-lg transition-all duration-200 flex items-center gap-2"
                            >
                                <i className="fas fa-filter"></i>
                                <span>تطبيق الفلتر</span>
                            </button>
                            <button 
                                onClick={handleResetFilters} 
                                className="px-6 py-3 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-all duration-200 flex items-center gap-2"
                            >
                                <i className="fas fa-redo"></i>
                                <span>إعادة تعيين</span>
                            </button>
                        </div>
                    </div>

                    {/* Table Section */}
                    <div className="bg-white rounded-xl shadow-sm overflow-hidden border border-gray-100">
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                    <tr className="bg-gray-50">
                                        <th className="py-4 px-6 text-right text-sm font-medium text-gray-500">الرقم</th>
                                        <th className="py-4 px-6 text-right text-sm font-medium text-gray-500">المزود</th>
                                        <th className="py-4 px-6 text-right text-sm font-medium text-gray-500">الهاتف</th>
                                        <th className="py-4 px-6 text-right text-sm font-medium text-gray-500">المعاملة</th>
                                        <th className="py-4 px-6 text-right text-sm font-medium text-gray-500">التاريخ</th>
                                        <th className="py-4 px-6 text-right text-sm font-medium text-gray-500">المبلغ</th>
                                        <th className="py-4 px-6 text-right text-sm font-medium text-gray-500">الحالة</th>
                                        <th className="py-4 px-6 text-right text-sm font-medium text-gray-500">الإجراءات</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-100">
                                    {payouts.map(payout => (
                                        <tr key={payout.id} className="hover:bg-gray-50 transition-colors">
                                            <td className="py-4 px-6 text-sm text-gray-600">{payout.id}</td>
                                            <td className="py-4 px-6 text-sm text-gray-800 font-medium">{payout.providerName}</td>
                                            <td className="py-4 px-6 text-sm text-gray-600">{payout.providerPhone}</td>
                                            <td className="py-4 px-6 text-sm text-gray-600">{payout.message}</td>
                                            <td className="py-4 px-6 text-sm text-gray-600">{new Date(payout.date).toLocaleDateString()}</td>
                                            <td className="py-4 px-6 text-sm font-medium text-green-600">{payout.amount} ريال</td>
                                            <td className="py-4 px-6">
                                                <span className="px-3 py-1 text-xs font-medium bg-green-100 text-green-800 rounded-full">
                                                    {payout.status}
                                                </span>
                                            </td>
                                            <td className="py-4 px-6">
                                                <button 
                                                    onClick={() => printInvoice(payout)} 
                                                    className="inline-flex items-center px-3 py-1.5 bg-blue-50 hover:bg-blue-100 text-blue-700 text-sm font-medium rounded-lg transition-colors duration-200"
                                                >
                                                    <i className="fas fa-print mr-2"></i>
                                                    طباعة الفاتورة
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Pagination */}
                    <div className="flex items-center justify-between bg-white rounded-xl shadow-sm p-4 border border-gray-100">
                        <button 
                            onClick={() => setPage(prev => Math.max(prev - 1, 1))} 
                            disabled={page === 1}
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            السابق
                        </button>
                        <span className="text-sm text-gray-600">
                            صفحة {page} من {Math.ceil(totalCount / pageSize)}
                        </span>
                        <button 
                            onClick={() => setPage(prev => prev + 1)} 
                            disabled={page * pageSize >= totalCount}
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            التالي
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}