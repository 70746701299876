import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { baseurl } from '../../constants';
import './UserDetails.css';

const UserEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({
        aboutText: '',
        fullName: '',
        email: '',
        userName: '',
        city: '',
        descAddress: '',
        createdAt: '',
    });

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };

    useEffect(() => {
        const fetchItem = async () => {
            const response = await axios.get(baseurl + `api/user/` + id);
            setItem(response.data);
        };
        fetchItem();
    }, [id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setItem(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(baseurl + `api/user/` + item.id, item);
            navigate(`/admin/user/detail/${id}`);
        } catch (error) {
            console.error('Error updating user:', error);
            // Handle error appropriately
        }
    };

    return (
        <div className="user-detail-container">
            <div className="user-detail-header">
                <h1 className="user-detail-title">تعديل المستخدم</h1>
                <p className="user-detail-subtitle">تعديل معلومات المستخدم</p>
            </div>

            <div className="user-detail-card">
                <div className="user-detail-profile">
                    {item.profileImage ? (
                        <img
                            src={baseurl + "/uploads/" + item.profileImage}
                            alt={item.fullName}
                            className="user-detail-avatar"
                        />
                    ) : (
                        <div className="user-detail-avatar">
                            <i className="bi bi-person" style={{ fontSize: '3rem' }}></i>
                        </div>
                    )}
                    <div className="user-detail-info">
                        <h2 className="user-detail-name">{item.fullName}</h2>
                        <p className="user-detail-email">{item.email}</p>
                        <p className="user-detail-created-at">Created at: {formatDate(item.createdAt)}</p>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="user-edit-form">
                    <div className="user-edit-grid">
                        <div className="user-edit-field">
                            <label className="user-edit-label">الاسم الكامل</label>
                            <input
                                type="text"
                                name="fullName"
                                value={item.fullName}
                                onChange={handleChanges}
                                className="user-edit-input"
                                placeholder="أدخل الاسم الكامل"
                            />
                        </div>

                        <div className="user-edit-field">
                            <label className="user-edit-label">البريد الإلكتروني</label>
                            <input
                                type="email"
                                name="email"
                                value={item.email}
                                onChange={handleChanges}
                                className="user-edit-input"
                                placeholder="أدخل البريد الإلكتروني"
                            />
                        </div>

                        <div className="user-edit-field">
                            <label className="user-edit-label">رقم الهاتف</label>
                            <input
                                type="text"
                                name="userName"
                                value={item.userName}
                                onChange={handleChanges}
                                className="user-edit-input"
                                placeholder="أدخل رقم الهاتف"
                            />
                        </div>

                        <div className="user-edit-field">
                            <label className="user-edit-label">المدينة</label>
                            <input
                                type="text"
                                name="city"
                                value={item.city}
                                onChange={handleChanges}
                                className="user-edit-input"
                                placeholder="أدخل المدينة"
                            />
                        </div>

                        <div className="user-edit-field" style={{ gridColumn: 'span 2' }}>
                            <label className="user-edit-label">نبذة عن المستخدم</label>
                            <textarea
                                name="aboutText"
                                value={item.aboutText}
                                onChange={handleChanges}
                                className="user-edit-input"
                                placeholder="أدخل نبذة عن المستخدم"
                                rows="4"
                            />
                        </div>

                        <div className="user-edit-field" style={{ gridColumn: 'span 2' }}>
                            <label className="user-edit-label">العنوان التفصيلي</label>
                            <textarea
                                name="descAddress"
                                value={item.descAddress}
                                onChange={handleChanges}
                                className="user-edit-input"
                                placeholder="أدخل العنوان التفصيلي"
                                rows="3"
                            />
                        </div>

                        <div className="user-edit-actions">
                            <button type="submit" className="user-detail-button primary">
                                <i className="bi bi-check2"></i>
                                حفظ التغييرات
                            </button>
                            <Link to={`/admin/user/detail/${id}`} className="user-detail-button secondary">
                                <i className="bi bi-x"></i>
                                إلغاء
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserEdit;