import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseurl } from '../../constants';
import './BookingList.css';
import DeleteConfirmationModal from '../../Components/DeleteConfirmationModal/DeleteConfirmationModal';

const StatusBadge = ({ status }) => {
    const getStatusColor = () => {
        const statusStr = String(status).toLowerCase();
        switch (statusStr) {
            case 'pending':
            case 'قيد الانتظار':
                return 'bg-warning';
            case 'confirmed':
            case 'مؤكد':
                return 'bg-success';
            case 'cancelled':
            case 'ملغي':
                return 'bg-danger';
            case 'completed':
            case 'مكتمل':
                return 'bg-info';
            default:
                return 'bg-secondary';
        }
    };

    return (
        <span className={`badge ${getStatusColor()} text-white`}>
            {status}
        </span>
    );
};

const PaymentMethodBadge = ({ method }) => {
    const getMethodColor = () => {
        switch (method?.toLowerCase()) {
            case 'cash':
            case 'نقدي':
                return 'bg-success';
            case 'card':
            case 'بطاقة':
                return 'bg-primary';
            case 'bank transfer':
            case 'تحويل بنكي':
                return 'bg-info';
            default:
                return 'bg-secondary';
        }
    };

    return (
        <span className={`badge ${getMethodColor()} text-white`}>
            {method}
        </span>
    );
};

const BookingList = () => {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [bookingToDelete, setBookingToDelete] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(baseurl + `api/booking?page=${pageNumber}`);
                setData(response.data.items);
                setTotalPages(Math.ceil(response.data.total / 10));
            } catch (error) {
                console.error('Error fetching bookings:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [pageNumber]);

    const initiateDelete = (booking) => {
        setBookingToDelete(booking);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        if (!bookingToDelete) return;
        
        setDeleteLoading(true);
        try {
            await axios.delete(baseurl + `api/booking/${bookingToDelete.id}`);
            setData(data.filter(item => item.id !== bookingToDelete.id));
            setShowDeleteModal(false);
            setBookingToDelete(null);
        } catch (error) {
            console.error('Error deleting booking:', error);
        } finally {
            setDeleteLoading(false);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const LoadingSkeleton = () => (
        <div className="row g-4">
            {[1, 2, 3, 4, 5, 6].map(i => (
                <div key={i} className="col-12 col-md-6 col-lg-4">
                    <div className="booking-card skeleton">
                        <div className="skeleton-content">
                            <div className="skeleton-line" style={{ width: '60%' }}></div>
                            <div className="skeleton-line" style={{ width: '40%' }}></div>
                            <div className="skeleton-line" style={{ width: '80%' }}></div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className="booking-container">
            <div className="booking-header">
                <h1 className="header-title">قائمة الحجوزات</h1>
                <div className="header-breadcrumb">
                    <i className="bx bx-home-alt"></i>
                    <span>الرئيسية</span>
                    <span>/</span>
                    <span>الحجوزات</span>
                </div>
            </div>

            {isLoading ? (
                <LoadingSkeleton />
            ) : (
                <>
                    <div className="row g-4">
                        {data.map((item, index) => {
                            // Safely convert status to string and get class
                            const statusStr = String(item.status || '').toLowerCase();
                            const getStatusClass = () => {
                                switch (statusStr) {
                                    case 'pending':
                                    case 'قيد الانتظار':
                                        return 'pending';
                                    case 'confirmed':
                                    case 'مؤكد':
                                        return 'confirmed';
                                    case 'cancelled':
                                    case 'ملغي':
                                        return 'cancelled';
                                    case 'completed':
                                    case 'مكتمل':
                                        return 'completed';
                                    default:
                                        return '';
                                }
                            };
                            
                            return (
                                <div key={item.id} className="col-12 col-md-6 col-lg-4">
                                    <div className={`booking-card ${getStatusClass()}`}>
                                        <div className="booking-card-header">
                                            <div className="booking-id">
                                                <h5>حجز #{item.id}</h5>
                                                <StatusBadge status={item.status} />
                                            </div>
                                            <div className="booking-cost">
                                                <strong>{item.totalCost} ريال</strong>
                                                <PaymentMethodBadge method={item.paymentMethod} />
                                            </div>
                                        </div>
                                        
                                        <div className="booking-card-body">
                                            <div className="booking-info">
                                                <div className="info-item">
                                                    <i className="fas fa-users"></i>
                                                    <span>{item.sets} أشخاص</span>
                                                </div>
                                                <div className="info-item">
                                                    <i className="fas fa-calendar-alt"></i>
                                                    <div className="dates">
                                                        <div>
                                                            <small>من:</small> {formatDate(item.startDate)}
                                                        </div>
                                                        <div>
                                                            <small>إلى:</small> {formatDate(item.endDate)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="booking-card-footer">
                                            <div className="actions-group">
                                                <Link to={`/admin/booking/detail/${item.id}`}>
                                                    <button className="btn-action btn-view" title="عرض التفاصيل">
                                                        <i className="fas fa-eye"></i>
                                                    </button>
                                                </Link>
                                                <Link to={`/admin/service/detail/${item.serviceId}`}>
                                                    <button className="btn-action btn-service" title="عرض الخدمة">
                                                        <i className="fas fa-box"></i>
                                                    </button>
                                                </Link>
                                                <Link to={`/admin/user/detail/${item.userId}`}>
                                                    <button className="btn-action btn-user" title="عرض المستخدم">
                                                        <i className="fas fa-user"></i>
                                                    </button>
                                                </Link>
                                                <Link to={`/admin/provider/detail/${item.providerId}`}>
                                                    <button className="btn-action btn-provider" title="عرض مزود الخدمة">
                                                        <i className="fas fa-user-tie"></i>
                                                    </button>
                                                </Link>
                                                <Link to={`/admin/booking/edit/${item.id}`}>
                                                    <button className="btn-action btn-edit" title="تعديل">
                                                        <i className="fas fa-edit"></i>
                                                    </button>
                                                </Link>
                                                <button 
                                                    className="btn-action btn-delete" 
                                                    onClick={() => initiateDelete(item)}
                                                    title="حذف"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {totalPages > 1 && (
                        <div className="pagination-container mt-4">
                            <div className="pagination">
                                <button
                                    className="page-btn"
                                    onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                                    disabled={pageNumber === 1}
                                >
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                                
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                                    <button
                                        key={page}
                                        className={`page-btn ${page === pageNumber ? 'active' : ''}`}
                                        onClick={() => setPageNumber(page)}
                                    >
                                        {page}
                                    </button>
                                ))}
                                
                                <button
                                    className="page-btn"
                                    onClick={() => setPageNumber(prev => Math.min(prev + 1, totalPages))}
                                    disabled={pageNumber === totalPages}
                                >
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}
            <DeleteConfirmationModal 
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleDelete}
                itemName={`#${bookingToDelete?.id}`}
                itemType="الحجز"
                loading={deleteLoading}
            />
        </div>
    );
};

export default BookingList;