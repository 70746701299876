import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { baseurl, formateDate } from '../constants';
import { useNavigate } from 'react-router-dom';
import './Chat.css';

// Icons
const SearchIcon = () => <svg width="16" height="16" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const AttachmentIcon = () => <svg width="20" height="20" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24"><path d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48" strokeLinecap="round" strokeLinejoin="round"/></svg>;
const EmojiIcon = () => <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm4-9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-8 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm4 8a5 5 0 004.95-4.2A.5.5 0 0016.5 14h-9a.5.5 0 00-.45.8A5 5 0 0012 19z"/></svg>;
const SendIcon = () => <svg width="20" height="20" fill="currentColor" viewBox="0 0 24 24"><path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/></svg>;
const MessageIcon = () => <svg width="48" height="48" fill="currentColor" viewBox="0 0 24 24"><path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"/></svg>;

const Chat = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    fetchChats();
  }, []);

  const fetchChats = async () => {
    try {
      const response = await axios.get(baseurl + 'admin/chats');
      console.log('Fetched chats:', response.data);
      setChats(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching chats:', error);
      setLoading(false);
    }
  };

  const fetchMessages = async (chat) => {
    setLoadingMessages(true);
    try {
      console.log('Fetching messages for users:', chat.user1.id, chat.user2.id);
      const response = await axios.get(baseurl + `admin/messages/${chat.user1.id}/${chat.user2.id}`);
      console.log('Fetched messages:', response.data);
      
      if (response.data && Array.isArray(response.data.messages)) {
        const sortedMessages = [...response.data.messages].sort((a, b) => 
          new Date(a.date) - new Date(b.date)
        );
        setMessages(sortedMessages);
        setSelectedChat({
          ...chat,
          user1: response.data.user1,
          user2: response.data.user2
        });
      } else {
        console.error('Invalid messages response:', response.data);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setMessages([]);
    } finally {
      setLoadingMessages(false);
    }
  };

  const handleChatSelect = (chat, e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Selected chat:', chat);
    setSelectedChat(chat);
    fetchMessages(chat);
  };

  const handleUserClick = (user) => {
    if (!user) return;
    
    if (user.role === 'provider') {
      navigate('/admin/provider/detail', { state: { user } });
    } else {
      navigate('/admin/user/detail/' + user.id, { state: { user } });
    }
  };

  const getProfileImage = (user) => {
    if (!user.profileImage) return `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.fullName}`;
    return baseurl + 'uploads/' + user.profileImage;
  };

  if (loading) {
    return <div className="loading">Loading chats...</div>;
  }

  return (
    <div className="chat-container">
      <div className="chat-list">
        <div className="chat-list-header">
          <div className="search-box">
            <SearchIcon />
            <input type="text" placeholder="Search chats..." />
          </div>
        </div>
        <div className="chat-list-content">
          {chats.map((chat) => (
            <div
              key={chat.user1.id + chat.user2.id}
              className={`chat-item ${
                selectedChat &&
                selectedChat.user1.id === chat.user1.id &&
                selectedChat.user2.id === chat.user2.id
                  ? 'selected'
                  : ''
              }`}
              onClick={(e) => handleChatSelect(chat, e)}
            >
              <div className="chat-item-avatar">
                <img src={getProfileImage(chat.user1)} alt={chat.user1.fullName} />
                <div className="online-indicator" />
              </div>
              <div className="chat-item-content">
                <div className="chat-item-header">
                  <span className="chat-item-name">{chat.user1.fullName}</span>
                  <span className="chat-item-time">{formateDate(chat.lastMessageDate)}</span>
                </div>
                <div className="chat-item-message">
                  {chat.lastMessage.message1}
                </div>
              </div>
              {chat.lastMessage.readed === 0 && (
                <div className="unread-indicator">1</div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="chat-messages mt-[100px]">
        {selectedChat ? (
          <>
            <div className="chat-header">
              <div 
                className="chat-header-avatar"
                onClick={() => handleUserClick(selectedChat.user1)}
                style={{ cursor: 'pointer' }}
              >
                <img src={getProfileImage(selectedChat.user1)} alt={selectedChat.user1.fullName} />
                <div className="online-indicator" />
              </div>
              <div className="chat-header-info">
                <h2>{selectedChat.user1.fullName}</h2>
                <div className="chat-header-status">
                  <div className="status-dot" />
                  Online
                </div>
              </div>
            </div>
            <div className="messages-container">
              {loadingMessages ? (
                <div className="messages-loading">Loading messages...</div>
              ) : messages.length > 0 ? (
                <>
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={`message ${
                        message.senderId === selectedChat.user1.id ? 'received' : 'sent'
                      }`}
                    >
                      <div 
                        className="message-avatar"
                        onClick={() => handleUserClick(
                          message.senderId === selectedChat.user1.id
                            ? selectedChat.user1
                            : selectedChat.user2
                        )}
                        style={{ cursor: 'pointer' }}
                      >
                        <img
                          src={getProfileImage(
                            message.senderId === selectedChat.user1.id
                              ? selectedChat.user1
                              : selectedChat.user2
                          )}
                          alt="Avatar"
                        />
                      </div>
                      <div className="message-content-wrapper">
                        <div className="message-bubble">
                          <div className="message-content">{message.message1}</div>
                        </div>
                        <div className="message-time">{formateDate(message.date)}</div>
                      </div>
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </>
              ) : (
                <div className="no-messages">No messages yet</div>
              )}
            </div>
          </>
        ) : (
          <div className="no-chat-selected">
            <div className="no-chat-icon">
              <MessageIcon />
            </div>
            <span>Select a chat to start messaging</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
