import React from 'react';
import './PaymentConfirmationModal.css';

const PaymentConfirmationModal = ({ 
    isOpen, 
    onClose, 
    onConfirm, 
    providerName,
    amount,
    loading = false 
}) => {
    if (!isOpen) return null;

    return (
        <div className="payment-modal-overlay" onClick={onClose}>
            <div className="payment-modal" onClick={e => e.stopPropagation()}>
                <div className="payment-modal-content">
                    <div className="payment-modal-icon">
                        <i className="fas fa-money-bill-wave"></i>
                    </div>
                    <h3 className="payment-modal-title">تأكيد الدفع</h3>
                    <p className="payment-modal-message">
                        هل أنت متأكد من دفع مبلغ{" "}
                        <span className="amount">
                            {amount} ريال
                        </span>
                        {" "}للمزود{" "}
                        <span className="provider-name">
                            {providerName}
                        </span>؟
                    </p>
                    <div className="payment-modal-actions">
                        <button 
                            className="btn-cancel" 
                            onClick={onClose}
                            disabled={loading}
                        >
                            إلغاء
                        </button>
                        <button 
                            className="btn-confirm" 
                            onClick={onConfirm}
                            disabled={loading}
                        >
                            {loading ? (
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            ) : (
                                <i className="fas fa-check me-2"></i>
                            )}
                            تأكيد الدفع
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentConfirmationModal;
