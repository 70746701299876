import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { baseurl, formateDate } from '../../constants';
import DeleteConfirmationModal from '../../Components/DeleteConfirmationModal/DeleteConfirmationModal';
import './ServiceList.css';

const FullScreenSlider = ({ images, mainImage, name, onClose, startIndex = 0 }) => {
    const [currentIndex, setCurrentIndex] = useState(startIndex);
    const allImages = images && images.length > 0 
        ? images.map(img => baseurl + "uploads/" + img.url)
        : [baseurl + "uploads/" + mainImage];

    const handlePrevious = () => {
        setCurrentIndex(prevIndex => 
            prevIndex === 0 ? allImages.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex(prevIndex => 
            prevIndex === allImages.length - 1 ? 0 : prevIndex + 1
        );
    };

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.key === 'Escape') onClose();
            if (e.key === 'ArrowLeft') handlePrevious();
            if (e.key === 'ArrowRight') handleNext();
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
    }, [onClose]);

    return (
        <div 
            className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ 
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                zIndex: 1060
            }}
            onClick={onClose}
        >
            <div 
                className="position-relative"
                style={{ maxWidth: '90%', maxHeight: '90vh' }}
                onClick={e => e.stopPropagation()}
            >
                <button
                    className="btn-close btn-close-white position-absolute top-0 end-0 m-3"
                    style={{ zIndex: 1061 }}
                    onClick={onClose}
                    aria-label="Close"
                />
                
                <img
                    src={allImages[currentIndex]}
                    alt={`${name} ${currentIndex + 1}`}
                    className="img-fluid"
                    style={{ 
                        maxHeight: '90vh',
                        objectFit: 'contain'
                    }}
                />

                {allImages.length > 1 && (
                    <>
                        <button
                            className="btn btn-dark position-absolute top-50 start-0 translate-middle-y ms-2"
                            onClick={handlePrevious}
                            style={{ opacity: 0.7 }}
                        >
                            <i className="fas fa-chevron-left"></i>
                        </button>
                        <button
                            className="btn btn-dark position-absolute top-50 end-0 translate-middle-y me-2"
                            onClick={handleNext}
                            style={{ opacity: 0.7 }}
                        >
                            <i className="fas fa-chevron-right"></i>
                        </button>
                        <div className="position-absolute bottom-0 start-50 translate-middle-x mb-4">
                            <div className="image-counter">
                                {currentIndex + 1}/{allImages.length}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const ImageSlider = ({ images, mainImage, name, onImageClick }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const allImages = images && images.length > 0 
        ? images.map(img => baseurl + "uploads/" + img.url)
        : [baseurl + "uploads/" + mainImage];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentIndex((prevIndex) => 
                prevIndex === allImages.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000);

        return () => clearInterval(timer);
    }, [allImages.length]);

    return (
        <div 
            className="position-relative" 
            style={{ height: '160px', cursor: 'pointer' }}
            onClick={() => onImageClick(currentIndex)}
        >
            {allImages.map((imgSrc, index) => (
                <img
                    key={index}
                    src={imgSrc}
                    alt={`${name} ${index + 1}`}
                    className="card-img-top position-absolute top-0 start-0"
                    style={{
                        height: '160px',
                        width: '100%',
                        objectFit: 'cover',
                        opacity: index === currentIndex ? 1 : 0,
                        transition: 'opacity 0.5s ease-in-out'
                    }}
                />
            ))}
            {allImages.length > 1 && (
                <div className="position-absolute bottom-0 start-50 translate-middle-x mb-2">
                    <div className="image-counter-small" style={{ fontSize: '0.7rem', padding: '0.25rem 0.5rem' }}>
                        {currentIndex + 1}/{allImages.length}
                    </div>
                </div>
            )}
        </div>
    );
};

const ServiceList = () => {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [orderNumber, setOrderNumber] = useState('');
    const [selectedImageData, setSelectedImageData] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [serviceToDelete, setServiceToDelete] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [providers, setProviders] = useState([]);
    // New state for filters
    const [filters, setFilters] = useState({
        categoryId: '',
        searchName: '',
        maxPrice: ''
    });
    const [categories, setCategories] = useState([]);

    // Fetch categories on component mount
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(baseurl + 'api/category');
                // Make sure we're accessing the correct property from the response
                setCategories(response.data.items || []);
            } catch (error) {
                console.error('Error fetching categories:', error);
                setCategories([]); // Set empty array on error
            }
        };
        fetchCategories();
    }, []);

    // Handle filter changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));
        setPageNumber(1); // Reset to first page when filters change
    };

    // Clear filters
    const clearFilters = () => {
        setFilters({
            categoryId: '',
            searchName: '',
            maxPrice: ''
        });
        setPageNumber(1);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let url = `${baseurl}api/service?page=${pageNumber}`;
                
                // Add filters to URL if they exist
                if (filters.categoryId) {
                    url += `&categoryId=${filters.categoryId}`;
                }
                if (filters.searchName) {
                    url += `&searchName=${filters.searchName}`;
                }
                if (filters.maxPrice) {
                    url += `&maxPrice=${filters.maxPrice}`;
                }

                const response = await axios.get(url);
                setData(response.data.items);
                setProviders(response.data.providers);
                setTotalPages(Math.ceil(response.data.total / 10));
            } catch (error) {
                console.error('Error fetching services:', error);
            }
            setLoading(false);
        };
        fetchData();
    }, [pageNumber, filters]);

    const initiateDelete = (service) => {
        setServiceToDelete(service);
        setShowDeleteModal(true);
    };

    const handleDelete = async () => {
        if (!serviceToDelete) return;
        
        setDeleteLoading(true);
        try {
            await axios.delete(baseurl + `api/service/${serviceToDelete.id}`);
            setData(data.filter(item => item.service.id !== serviceToDelete.id));
            setShowDeleteModal(false);
            setServiceToDelete(null);
        } catch (error) {
            console.error('Error deleting service:', error);
        } finally {
            setDeleteLoading(false);
        }
    };

    const cancelDelete = () => {
        setShowDeleteModal(false);
        setServiceToDelete(null);
    };

    const handleOrderUpdate = async () => {
        try {
            await axios.patch(
                baseurl + `api/service/${selectedServiceId}/arrange`, 
                { arrange: parseInt(orderNumber) },  // Send as an object with 'arrange' property
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            // Update the local state
            setData(data.map(item => 
                item.service.id === selectedServiceId 
                ? { ...item, service: { ...item.service, arrange: parseInt(orderNumber) } }
                : item
            ));
            
            setShowOrderModal(false);
            setOrderNumber('');
            setSelectedServiceId(null);
        } catch (error) {
            console.error('Error updating service arrangement:', error);
        }
    };

    
    const openOrderModal = (serviceId) => {
        setSelectedServiceId(serviceId);
        const service = data.find(item => item.service.id === serviceId);
        setOrderNumber(service.service.arrange?.toString() || '');
        setShowOrderModal(true);
    };

    return (
        <div className="container-fluid p-4">
          

            {/* Filters Section */}
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row g-3">
                        <div className="col-12 col-md-4">
                            <label className="form-label">التصنيف</label>
                            <select 
                                className="form-select"
                                name="categoryId"
                                value={filters.categoryId}
                                onChange={handleFilterChange}
                            >
                                <option value="">جميع التصنيفات</option>
                                {Array.isArray(categories) && categories.map(category => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-label">البحث بالاسم</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="ادخل اسم الخدمة"
                                name="searchName"
                                value={filters.searchName}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <label className="form-label">السعر الأقصى</label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="السعر الأقصى"
                                name="maxPrice"
                                value={filters.maxPrice}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="col-12 text-end">
                            <button 
                                className="btn btn-secondary me-2"
                                onClick={clearFilters}
                            >
                                مسح الفلتر
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="text-center py-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">جاري التحميل...</span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="row g-3">
                        {data.map(item => (
                            <div key={item.service.id} className="col-12 col-md-6 col-lg-3">
                                <div className="card h-100 shadow-sm">
                                    <ImageSlider 
                                        images={item.images} 
                                        mainImage={item.service.image}
                                        name={item.service.name}
                                        onImageClick={(index) => setSelectedImageData({
                                            images: item.images,
                                            mainImage: item.service.image,
                                            name: item.service.name,
                                            startIndex: index
                                        })}
                                    />
                                    <div className="position-absolute top-0 end-0 p-2" style={{ zIndex: 1 }}>
                                        <span className="badge bg-primary" style={{ fontSize: '0.7rem', padding: '0.35em 0.65em' }}>
                                            {item.service.city}
                                        </span>
                                    </div>
                                    <div className="card-body p-2">
                                        <div className="d-flex justify-content-between align-items-start mb-1">
                                            <div>
                                                <h6 className="card-title mb-0">{item.service.name}</h6>
                                                <small className="text-muted" style={{ fontSize: '0.7rem' }}>#{item.service.id}</small>
                                                {item.service.arrange && (
                                                    <div className="mt-1">
                                                        <small className="text-muted" style={{ fontSize: '0.7rem' }}>ترتيب: {item.service.arrange}</small>
                                                    </div>
                                                )}
                                            </div>
                                            <h6 className="text-primary mb-0">{item.service.price} ريال</h6>
                                        </div>
                                        <p className="card-text text-truncate mb-2" style={{ maxWidth: '100%', fontSize: '0.8rem' }}>
                                            {item.service.desc || 'لا يوجد وصف'}
                                        </p>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <small className="text-muted" style={{ fontSize: '0.7rem' }}>
                                                <i className="far fa-clock ms-1"></i>
                                                {formateDate(item.service.createdAt)}
                                            </small>
                                            <div className="btn-group">
                                                <button
                                                    onClick={() => openOrderModal(item.service.id)}
                                                    className="btn btn-sm btn-outline-secondary py-0 px-1"
                                                    title="ترتيب الخدمة"
                                                >
                                                    <i className="fas fa-sort-numeric-down"></i>
                                                </button>
                                                <Link 
                                                    to={`/admin/service/detail/${item.service.id}`} 
                                                    state={{ row: item.service }}
                                                    className="btn btn-sm btn-outline-primary py-0 px-1"
                                                    title="عرض"
                                                >
                                                    <i className="fas fa-eye"></i>
                                                </Link>
                                                <Link 
                                                    to={`/admin/provider/detail`}
                                                    state={{ user: providers.find(p => p.id === item.service.userId) }}
                                                    className="btn btn-sm btn-outline-info py-0 px-1"
                                                    title="عرض مزود الخدمة"
                                                >
                                                    <i className="fas fa-user"></i>
                                                </Link>
                                                <Link 
                                                    to={`/admin/service/edit/${item.service.id}`}
                                                    state={{ row: item.service }}
                                                    className="btn btn-sm btn-outline-success py-0 px-1"
                                                    title="تعديل"
                                                >
                                                    <i className="fas fa-edit"></i>
                                                </Link>
                                                <button 
                                                    className="btn btn-sm btn-outline-danger py-0 px-1"
                                                    onClick={() => initiateDelete(item.service)}
                                                    title="حذف"
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Pagination */}
                    <nav className="mt-4">
                        <ul className="pagination justify-content-center">
                            <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                <button 
                                    className="page-link" 
                                    onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                                >
                                    <i className="fas fa-chevron-right me-1"></i>
                                    السابق
                                </button>
                            </li>
                            {[...Array(totalPages)].map((_, index) => (
                                <li 
                                    key={index} 
                                    className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}
                                >
                                    <button 
                                        className="page-link" 
                                        onClick={() => setPageNumber(index + 1)}
                                    >
                                        {index + 1}
                                    </button>
                                </li>
                            ))}
                            <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                <button 
                                    className="page-link" 
                                    onClick={() => setPageNumber(prev => Math.min(prev + 1, totalPages))}
                                >
                                    التالي
                                    <i className="fas fa-chevron-left ms-1"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </>
            )}

            {/* Order Modal */}
            {showOrderModal && (
                <div style={{
                    display: showOrderModal ? 'block' : 'none',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1050,
                }}>
                    <div style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%',
                        maxWidth: '500px',
                        margin: 0,
                        zIndex: 1051,
                    }} className="modal-dialog">
                        <div style={{
                            backgroundColor: '#fff',
                            borderRadius: '0.3rem',
                            boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
                        }} className="modal-content">
                            <div style={{
                                padding: '1rem',
                                borderBottom: '1px solid #dee2e6',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }} className="modal-header">
                                <h5 className="modal-title">تحديد ترتيب الخدمة</h5>
                                <button 
                                    type="button" 
                                    className="btn-close" 
                                    onClick={() => setShowOrderModal(false)}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div style={{
                                padding: '1rem'
                            }} className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="orderInput" className="form-label">رقم الترتيب</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="orderInput"
                                        value={orderNumber}
                                        onChange={(e) => setOrderNumber(e.target.value)}
                                        placeholder="أدخل رقم الترتيب"
                                        min="1"
                                    />
                                </div>
                            </div>
                            <div style={{
                                padding: '1rem',
                                borderTop: '1px solid #dee2e6',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '0.5rem'
                            }} className="modal-footer">
                                <button 
                                    type="button" 
                                    className="btn btn-secondary" 
                                    onClick={() => setShowOrderModal(false)}
                                >
                                    إلغاء
                                </button>
                                <button 
                                    type="button" 
                                    className="btn btn-primary" 
                                    onClick={handleOrderUpdate}
                                >
                                    حفظ
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <DeleteConfirmationModal 
                isOpen={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleDelete}
                itemName={serviceToDelete?.name}
                itemType="الخدمة"
                loading={deleteLoading}
            />

            {/* Full Screen Image Slider */}
            {selectedImageData && (
                <FullScreenSlider
                    {...selectedImageData}
                    onClose={() => setSelectedImageData(null)}
                />
            )}
        </div>
    );
};

export default ServiceList;