import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { baseurl, formateDate } from '../../constants';
import './ServiceDetail.css';

const Gallery = ({ images = [] }) => {
    const [mainImage, setMainImage] = useState(0);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handlePrevious = () => {
        setMainImage(prev => prev === 0 ? images.length - 1 : prev - 1);
    };

    const handleNext = () => {
        setMainImage(prev => prev === images.length - 1 ? 0 : prev + 1);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Escape') setIsFullscreen(false);
        if (e.key === 'ArrowLeft') handlePrevious();
        if (e.key === 'ArrowRight') handleNext();
    };

    useEffect(() => {
        if (isFullscreen) {
            window.addEventListener('keydown', handleKeyPress);
            return () => window.removeEventListener('keydown', handleKeyPress);
        }
        return undefined;
    }, [isFullscreen, handleKeyPress]);

    if (!images || images.length === 0) return null;

    return (
        <>
            <div className="gallery-container">
                <div className="main-image-container">
                    <img 
                        src={baseurl + "uploads/" + images[mainImage].url} 
                        alt="" 
                        className="main-image"
                        onClick={() => setIsFullscreen(true)}
                    />
                    <div className="image-counter">
                        {mainImage + 1}/{images.length}
                    </div>
                    {images.length > 1 && (
                        <>
                            <button className="nav-button prev" onClick={handlePrevious}>
                                <i className="fas fa-chevron-right"></i>
                            </button>
                            <button className="nav-button next" onClick={handleNext}>
                                <i className="fas fa-chevron-left"></i>
                            </button>
                            <button className="fullscreen-button" onClick={() => setIsFullscreen(true)}>
                                <i className="fas fa-expand"></i>
                            </button>
                        </>
                    )}
                </div>
                {images.length > 1 && (
                    <div className="thumbnails-container">
                        {images.map((image, index) => (
                            <div 
                                key={index}
                                className={`thumbnail ${index === mainImage ? 'active' : ''}`}
                                onClick={() => setMainImage(index)}
                            >
                                <img 
                                    src={baseurl + "uploads/" + image.url} 
                                    alt="" 
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {isFullscreen && (
                <div className="fullscreen-overlay" onClick={() => setIsFullscreen(false)}>
                    <div className="fullscreen-content" onClick={e => e.stopPropagation()}>
                        <img 
                            src={baseurl + "uploads/" + images[mainImage].url} 
                            alt="" 
                        />
                        <div className="fullscreen-controls">
                            <button className="nav-button prev" onClick={handlePrevious}>
                                <i className="fas fa-chevron-right"></i>
                            </button>
                            <div className="image-counter">
                                {mainImage + 1}/{images.length}
                            </div>
                            <button className="nav-button next" onClick={handleNext}>
                                <i className="fas fa-chevron-left"></i>
                            </button>
                        </div>
                        <button 
                            className="close-button"
                            onClick={() => setIsFullscreen(false)}
                        >
                            <i className="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

const ServiceDetail = () => {
    const { id } = useParams();
    const [item, setItem] = useState({});
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const fetchItem = async () => {
            setLoading(true);
            try {
                const response = await axios.get(baseurl + `api/service/` + id);
                setItem(response.data.item);
                setImages(response.data.images || []);
            } catch (error) {
                console.error('Error fetching service:', error);
            }
            setLoading(false);
        };
        fetchItem();
    }, [id]);

    if (loading) {
        return (
            <div className="service-detail-loader">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">جاري التحميل...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="service-detail-container">
            {/* Header Section */}
            <div className="service-header">
                <div className="breadcrumb">
                    <Link to="/admin/service" className="breadcrumb-item">
                        <i className="fas fa-home"></i>
                        الرئيسية
                    </Link>
                    <i className="fas fa-chevron-left mx-2"></i>
                    <span className="breadcrumb-item active">تفاصيل الخدمة</span>
                </div>

                <div className="header-content">
                    <div className="header-main">
                        <h1 className="service-title">
                            {item.name}
                            <span className="service-id">#{item.id}</span>
                        </h1>
                        <div className="service-badges">
                            <span className={`status-badge ${item.isBooked ? 'booked' : 'available'}`}>
                                <i className={`fas fa-${item.isBooked ? 'lock' : 'check-circle'}`}></i>
                                {item.isBooked ? 'محجوز' : 'متاح'}
                            </span>
                            <span className="price-badge">
                                <i className="fas fa-tag"></i>
                                {item.price} ريال/ساعة
                            </span>
                        </div>
                    </div>

                    <div className="header-actions">
                        <Link to={`/admin/service/edit/${id}`} className="btn btn-primary">
                            <i className="fas fa-edit me-2"></i>
                            تعديل الخدمة
                        </Link>
                    </div>
                </div>
            </div>

            <div className="service-content">
                <div className="row">
                    {/* Left Column - Gallery and Description */}
                    <div className="col-lg-8">
                        {/* Gallery Section */}
                        {images.length > 0 && (
                            <div className="content-card gallery-card">
                                <div className="card-header">
                                    <h2>
                                        <i className="fas fa-images"></i>
                                        معرض الصور
                                    </h2>
                                </div>
                                <Gallery images={images} />
                            </div>
                        )}

                        {/* Description Section */}
                        <div className="content-card">
                            <div className="card-header">
                                <h2>
                                    <i className="fas fa-align-left"></i>
                                    وصف الخدمة
                                </h2>
                            </div>
                            <div className="card-body">
                                <p className="service-description">
                                    {item.desc || 'لا يوجد وصف متاح'}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Right Column - Details and Location */}
                    <div className="col-lg-4">
                        {/* Quick Info */}
                        <div className="content-card">
                            <div className="card-header">
                                <h2>
                                    <i className="fas fa-info-circle"></i>
                                    معلومات سريعة
                                </h2>
                            </div>
                            <div className="card-body">
                                <div className="info-list">
                                    <div className="info-item">
                                        <i className="fas fa-users"></i>
                                        <div className="info-content">
                                            <label>الحد الأدنى للأشخاص</label>
                                            <span>{item.minPersons || 0} أشخاص</span>
                                        </div>
                                    </div>
                                    <div className="info-item">
                                        <i className="fas fa-calendar-check"></i>
                                        <div className="info-content">
                                            <label>أقصى عدد للحجوزات</label>
                                            <span>{item.maxBookings || 0} حجز</span>
                                        </div>
                                    </div>
                                    <div className="info-item">
                                        <i className="fas fa-clock"></i>
                                        <div className="info-content">
                                            <label>تاريخ الإضافة</label>
                                            <span>{formateDate(item.createdAt)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Location Info */}
                        <div className="content-card">
                            <div className="card-header">
                                <h2>
                                    <i className="fas fa-map-marker-alt"></i>
                                    معلومات الموقع
                                </h2>
                            </div>
                            <div className="card-body">
                                <div className="info-list">
                                    <div className="info-item">
                                        <i className="fas fa-city"></i>
                                        <div className="info-content">
                                            <label>المدينة</label>
                                            <span>{item.city || 'غير محدد'}</span>
                                        </div>
                                    </div>
                                    <div className="info-item">
                                        <i className="fas fa-map-pin"></i>
                                        <div className="info-content">
                                            <label>العنوان</label>
                                            <span>{item.address || 'غير محدد'}</span>
                                        </div>
                                    </div>
                                    {item.descAddress && (
                                        <div className="info-item">
                                            <i className="fas fa-directions"></i>
                                            <div className="info-content">
                                                <label>وصف العنوان</label>
                                                <span>{item.descAddress}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetail;