// src/api/payoutApi.js
import axios from 'axios';
import { baseurl } from '../../constants';

const BASE_URL = baseurl+"api/payout"; 


export const getPayouts = async (page, pageSize, providerId, amountFilter, fromDate, toDate) => {
    const formData = new FormData();
    formData.append('page', page);
    formData.append('pageSize', pageSize);
    if (providerId) formData.append('providerId', providerId);
    if (amountFilter) formData.append('amountFilter', amountFilter);
    if (fromDate) formData.append('fromDate', fromDate );
    if (toDate) formData.append('toDate', toDate);
        const response = await fetch(`${BASE_URL}/list`, {
            method: "POST",
            body: formData,
        });

        // Check if the response is ok (status in the range 200-299)
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parse the JSON response
        const data = await response.json();
        return data;
};

export const getPayoutById = async (id) => {
    const response = await axios.get(`${BASE_URL}/${id}`);
    return response.data;
};

export const createPayout = async (payout) => {
    const response = await axios.post(BASE_URL, payout);
    return response.data;
};

export const updatePayout = async (id, payout) => {
    const response = await axios.put(`${BASE_URL}/${id}`, payout);
    return response.data;
};

export const deletePayout = async (id) => {
    await axios.delete(`${BASE_URL}/${id}`);
};