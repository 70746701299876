import { toast } from "react-hot-toast";

export const baseurl = "https://api.tall3at.com/"
//export const baseurl = "https://localhost:7023/"
export const formateDate = function formatDateTimeToAMPM(dateString) {
  const date = new Date(dateString);

  // Format date as DD-MM-YYYY
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  // Format time as HH:MM
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;

  return `${formattedDate}. ${formattedTime}`;
}
export const font = "/Users/ahmad/Downloads/Amiri/Amiri-Regular.ttf"
export const onInputInvalid = (e) =>
e.target.setCustomValidity("هذا الحقل مطلوب")
export const OnInput = (e) => e.target.setCustomValidity("")
export const uploadImage=async (file)=>{
    const formdata = new FormData();
    formdata.append("image", file);
      const response = await fetch(baseurl+ "image/upload", {
        method: "POST",
        headers: {
            "Authorization":"Bearer "+localStorage.getItem("token")
          },
        body: formdata,
      });
      const json = await response.json();
      return json.image;
}

export const successNotify = () => toast.success('تمت العملية بنجاح');
export const errorNotify = () => toast.error('هناك خطأ في البيانات الرجاء التأكد وإعادة المحاولة');
