import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { baseurl } from '../../constants';

const CategoryEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        name_en: '',
        desc: '',
        desc_en: '',
        image: null
    });
    const [previewImage, setPreviewImage] = useState('');

    useEffect(() => {
        const fetchItem = async () => {
            setLoading(true);
            try {
                const response = await axios.get(baseurl + `api/category/${id}`);
                const item = response.data.item;
                setFormData({
                    name: item.name || '',
                    name_en: item.name_en || '',
                    desc: item.desc || '',
                    desc_en: item.desc_en || '',
                    image: null
                });
                if (item.image) {
                    setPreviewImage(baseurl + "uploads/" + item.image);
                }
            } catch (error) {
                console.error('Error fetching category:', error);
            }
            setLoading(false);
        };

        if (id) {
            fetchItem();
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFormData(prev => ({
                ...prev,
                image: file
            }));
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);

        try {
            const data = new FormData();
            Object.keys(formData).forEach(key => {
                if (formData[key] !== null) {
                    data.append(key, formData[key]);
                }
            });

            if (id) {
                await axios.put(baseurl + `api/category/${id}`, data);
            } else {
                await axios.post(baseurl + 'api/category', data);
            }

            navigate('/admin/category');
        } catch (error) {
            console.error('Error saving category:', error);
            setSaving(false);
        }
    };

    if (loading) {
        return (
            <div className="container-fluid p-4">
                <div className="text-center py-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">جاري التحميل...</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid p-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="m-0">{id ? 'تعديل الفئة' : 'إضافة فئة جديدة'}</h3>
                <Link to="/admin/category" className="btn btn-outline-secondary">
                    <i className="fas fa-arrow-right ms-1"></i>
                    العودة للقائمة
                </Link>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="row g-4">
                    {/* Main Info Card */}
                    <div className="col-md-8">
                        <div className="card shadow-sm">
                            <div className="card-header bg-transparent">
                                <h5 className="card-title m-0">المعلومات الأساسية</h5>
                            </div>
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">الاسم بالعربية</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">الاسم بالإنجليزية</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name_en"
                                                value={formData.name_en}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">الوصف بالعربية</label>
                                            <textarea
                                                className="form-control"
                                                name="desc"
                                                value={formData.desc}
                                                onChange={handleInputChange}
                                                rows="3"
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label className="form-label">الوصف بالإنجليزية</label>
                                            <textarea
                                                className="form-control"
                                                name="desc_en"
                                                value={formData.desc_en}
                                                onChange={handleInputChange}
                                                rows="3"
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Image Card */}
                    <div className="col-md-4">
                        <div className="card shadow-sm">
                            <div className="card-header bg-transparent">
                                <h5 className="card-title m-0">الصورة</h5>
                            </div>
                            <div className="card-body">
                                <div className="text-center mb-3">
                                    {previewImage ? (
                                        <img
                                            src={previewImage}
                                            alt="Preview"
                                            className="img-fluid rounded"
                                            style={{ maxHeight: '200px', objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <div className="text-muted">لا توجد صورة</div>
                                    )}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">اختر صورة</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="mt-4">
                    <button 
                        type="submit" 
                        className="btn btn-primary"
                        disabled={saving}
                    >
                        {saving ? (
                            <>
                                <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                                جاري الحفظ...
                            </>
                        ) : (
                            <>
                                <i className="fas fa-save ms-1"></i>
                                حفظ التغييرات
                            </>
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CategoryEdit;
