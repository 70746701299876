import './App.css';
import Dashboard from './Components/Dashboard';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Chat from './Pages/Chat';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Users from './Pages/Users/Index';
import Providers from './Pages/Providers/Index';
import ProviderDetail from './Pages/Providers/Detail';
import EditProvider from './Pages/Providers/Edit';
import TripList from './Pages/Trips/TripList';
import TripEdit from './Pages/Trips/TripEdit';
import TripDetail from './Pages/Trips/TripDetail';
import BookingList from './Pages/Bookings/BookingList';
import BookingEdit from './Pages/Bookings/BookingEdit';
import BookingDetail from './Pages/Bookings/BookingDetail';
import CategoryList from './Pages/Categories/CategoryList';
import CategoryEdit from './Pages/Categories/CategoryEdit';
import CategoryDetail from './Pages/Categories/CategoryDetail';
import ServiceList from './Pages/Services/ServiceList';
import ServiceEdit from './Pages/Services/ServiceEdit';
import ServiceDetail from './Pages/Services/ServiceDetail';
import MenuList from './Pages/Menu/MenuList';
import MenuEdit from './Pages/Menu/MenuEdit';
import MenuDetail from './Pages/Menu/MenuDetail';

import UserList from './Pages/User';
import UserEdit from './Pages/User/edit';
import UserDetail from './Pages/User/detail';

import OrderList from './Pages/Order';
import OrderEdit from './Pages/Order/edit';
import OrderDetail from './Pages/Order/detail';

import MenuCategoryList from './Pages/MenuCategory';
import MenuCategoryEdit from './Pages/MenuCategory/edit';
import MenuCategoryDetail from './Pages/MenuCategory/detail';
import Transactions from './Pages/Transactions/Transactions';
import ProviderWalet from './Pages/Providers/Wallet';

import PayoutList from './Pages/Payouts/PayoutList';
import PayoutForm from './Pages/Payouts/PayoutForm';
import PayoutDetails from './Pages/Payouts/PayoutDetails'
import TransactionList from './Pages/Transactions/Transactions';

// Import Report Pages
import ServicesReport from './Pages/Reports/ServicesReport';
import BookingsReport from './Pages/Reports/BookingsReport';
import UsersReport from './Pages/Reports/UsersReport';

function App() {
  return (
    <div className="App">
      <Router >
        <Routes >
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<Dashboard />}>
            <Route path="" element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="/admin/providers" element={<Providers />} />
            <Route path="users" element={<Users />} />

            {/* Providers */}
            <Route path="provider/detail" element={<ProviderDetail />} />
            <Route path="provider/edit" element={<EditProvider />} />
            <Route path="provider/wallet" element={<ProviderWalet />} />

            <Route path='trip'  element={<TripList/>} />
            <Route path='trip/edit/:id' element={<TripEdit/> }/>
            <Route path='trip/detail/:id' element={<TripDetail/>} />

            <Route path='booking/'  element={<BookingList/>} />
            <Route path='booking/edit/:id' element={<BookingEdit />} />
            <Route path='booking/detail/:id' element={<BookingDetail/>} />

            <Route path='category/'  element={<CategoryList/>} />
            <Route path='category/edit/:id' element={<CategoryEdit/>} />
            <Route path='category/detail/:id' element={<CategoryDetail/>} />

            <Route path='service/'  element={<ServiceList/>} />
            <Route path='service/edit/:id' element={<ServiceEdit/>} />
            <Route path='service/detail/:id' element={<ServiceDetail/>} />

            <Route path='menu/'  element={<MenuList/>} />
            <Route path='menu/edit/:id' element={<MenuEdit/>} />
            <Route path='menu/detail/:id' element={<MenuDetail/>} />

            <Route path='user/'  element={<UserList/>} />
            <Route path='user/edit/:id' element={<UserEdit/>} />
            <Route path='user/detail/:id' element={<UserDetail/>} />

            <Route path='order/'  element={<OrderList/>} />
            <Route path='order/edit/:id' element={<OrderEdit/>} />
            <Route path='order/detail/:id' element={<OrderDetail/>} />
            
            <Route path='transactions' element={<TransactionList/>} />

            <Route path='menucategory/'  element={<MenuCategoryList/>} />
            <Route path='menucategory/edit/:id' element={<MenuCategoryEdit/>} />
            <Route path='menucategory/detail/:id' element={<MenuCategoryDetail/>} />


             <Route path="payouts"  element={<PayoutList/>} />
             <Route path="payouts/add" element={<PayoutForm/>} />
             <Route path="payouts/edit/:id" element={<PayoutForm/>} />
             <Route path="payouts/details/:id" element={<PayoutDetails/>} />

            {/* Reports Routes */}
            <Route path="reports/services" element={<ServicesReport />} />
            <Route path="reports/bookings" element={<BookingsReport />} />
            <Route path="reports/users" element={<UsersReport />} />
            <Route path="chats" element={<Chat />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
