import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, formateDate } from "../../constants";
import axios from 'axios';
import './Providers.css';
import DeleteConfirmationModal from '../../Components/DeleteConfirmationModal/DeleteConfirmationModal';
import PaymentConfirmationModal from '../../Components/PaymentConfirmationModal/PaymentConfirmationModal';
import Pagination from '../../Components/Pagination/Pagination';


const Providers = () => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loader, showLoader, hideLoader] = useLoader();
  const [balanceFilter, setBalanceFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [providerToDelete, setProviderToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const formdata = new FormData();
        formdata.append("pageNumber", pageNumber);
        formdata.append("pageSize", pageSize);
        if (balanceFilter) {
          formdata.append("balanceFilter", balanceFilter);
        }
        if (searchTerm) {
          formdata.append("searchTerm", searchTerm);
        }
        const response = await axios.post(baseurl + 'user/get-providers', formdata);
        setData(response.data.items);
        setTotalCount(response.data.totalCount);
      } catch (error) {
        console.error('Error fetching providers:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [pageNumber, pageSize, balanceFilter, searchTerm]);

  const totalPages = Math.ceil(totalCount / pageSize);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setPageNumber(newPage);
  };

  const initiateDelete = (provider) => {
    setProviderToDelete(provider);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (!providerToDelete) return;
    
    setDeleteLoading(true);
    try {
      await axios.delete(baseurl + `api/provider/${providerToDelete.id}`);
      setData(data.filter(item => item.id !== providerToDelete.id));
      setShowDeleteModal(false);
      setProviderToDelete(null);
    } catch (error) {
      console.error('Error deleting provider:', error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleWalletClick = (provider) => {
    setSelectedProvider(provider);
    setShowPaymentModal(true);
  };

  const handlePayment = async () => {
    if (!selectedProvider) return;
    
    setPaymentLoading(true);
    try {
      const formData = new FormData();
      formData.append('id', selectedProvider.id);
      formData.append('amount', selectedProvider.balance);
      await axios.post(baseurl + 'provider-pay', formData, {
      });
      
      // Update the provider's balance in the list
      setData(data.map(item => 
        item.id === selectedProvider.id 
          ? { ...item, balance: 0 } 
          : item
      ));
      
      setShowPaymentModal(false);
      setSelectedProvider(null);
    } catch (error) {
      console.error('Error processing payment:', error);
    } finally {
      setPaymentLoading(false);
    }
  };

  const LoadingSkeleton = () => (
    <div className="providers-card">
      {[1, 2, 3, 4, 5].map(i => (
        <div key={i} className="skeleton" style={{ height: '60px', margin: '8px' }}></div>
      ))}
    </div>
  );

  return (
    <div className="providers-container">
      {loader}
      
      <div className="providers-header">
        <h1 className="providers-title">قائمة المزودين</h1>
        <div className="search-container">
          <input
            type="text"
            className="search-input"
            placeholder="البحث عن مزود..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <i className="bi bi-search search-icon"></i>
        </div>
        <select
          className="balance-filter"
          value={balanceFilter}
          onChange={(e) => setBalanceFilter(e.target.value)}
        >
          <option value="">كل الأرصدة</option>
          <option value="positive">رصيد موجب</option>
          <option value="negative">رصيد سالب</option>
        </select>
      </div>

      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <>
          <div className="providers-grid">
            {data.map((item) => (
              <div key={item.id} className="provider-card">
                <div className="provider-card-header">
                  {item.profileImage ? (
                    <img
                      src={baseurl + "/uploads/" + item.profileImage}
                      className="provider-image"
                      alt={item.fullName}
                    />
                  ) : (
                    <div className="provider-image-placeholder">
                      <i className="bi bi-person"></i>
                    </div>
                  )}
                  <div className="provider-info">
                    <h3 className="provider-name">{item.fullName}</h3>
                    <p className="provider-email">{item.email}</p>
                  </div>
                </div>
                <div className="provider-details">
                  <p className="provider-id">
                    رقم المزود: {item.id}
                  </p>
                  <p className="provider-balance">
                    الرصيد: {item.balance} ريال
                  </p>
                  <p className="provider-date">
                    تاريخ التسجيل: {formateDate(item.createdAt)}
                  </p>
                </div>
                <div className="provider-actions">
                  <Link 
                    to={`/admin/provider/detail`} 
                    state={{ user: item }}
                    className="action-button view"
                  >
                    <i className="bi bi-eye"></i>
                  </Link>
                  <Link to={`/admin/provider/edit`}  state={{ row: item }}
                  className="action-button edit">
                    <i className="bi bi-pencil"></i>
                  </Link>
                  <button onClick={() => initiateDelete(item)} className="action-button delete">
                    <i className="bi bi-trash"></i>
                  </button>
                  <button onClick={() => handleWalletClick(item)} className="action-button wallet">
                    <i className="bi bi-wallet2"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
          {totalPages > 0 && (
            <Pagination 
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
      <DeleteConfirmationModal 
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        itemName={providerToDelete?.fullName}
        itemType="المزود"
        loading={deleteLoading}
      />
      <PaymentConfirmationModal 
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        onConfirm={handlePayment}
        providerName={selectedProvider?.fullName}
        amount={selectedProvider?.balance}
        loading={paymentLoading}
      />
    </div>
  );
};

export default Providers;