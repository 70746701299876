import { useEffect, useState } from "react";
import { baseurl } from "../constants";
import useLoader from "../Components/loader/useLoader";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import './Home.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement } from 'chart.js';
import { Bar, Pie, Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement);

// Custom chart theme
const chartTheme = {
  colors: ['#4A90E2', '#50E3C2', '#F5A623', '#D0021B', '#BD10E0', '#9013FE'],
  fontFamily: "'Tajawal', sans-serif",
  gridColor: '#E2E8F0',
  textColor: '#4A5568'
};

// Status mapping
const bookingStatusMap = {
  0: 'قيد الانتظار',
  1: 'مكتمل',
  2: 'ملغي'
};

export default function Home() {
  const navigateTo = useNavigate();
  const [data, setData] = useState();
  const [loader, showLoader, hideLoader] = useLoader();
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    showLoader();
    setIsLoading(true);
    try {
      const response = await fetch(baseurl + "dashboard-home", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      setData(json);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('هل أنت متأكد أنك تريد حذف هذا العنصر؟')) {
      try {
        await axios.delete(baseurl + `api/service/${id}`);
        getData();
      } catch (error) {
        console.error('Error deleting service:', error);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const StatCard = ({ title, value, subValue, icon, className, onClick }) => (
    <div className={`stat-card ${className}`} onClick={onClick}>
      <div className="stat-content">
        <div className="stat-info">
          <p>{title}</p>
          <h3>{value}</h3>
          <div className="stat-footer">
            <i className="bi bi-arrow-up"></i>
            <span>{subValue}</span>
          </div>
        </div>
        <div className="stat-icon">
          <i className={icon}></i>
        </div>
      </div>
    </div>
  );

  const userGrowthChartData = data?.charts?.userGrowth ? {
    labels: [...new Set(data.charts.userGrowth.map(item => 
      new Date(item.month).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
    ))],
    datasets: [
      {
        label: 'المستخدمين',
        data: data.charts.userGrowth
          .filter(item => item.role === 'user')
          .map(item => item.count),
        backgroundColor: chartTheme.colors[0],
        borderColor: chartTheme.colors[0],
        borderWidth: 2,
        borderRadius: 8,
        maxBarThickness: 40
      },
      {
        label: 'مزودي الخدمة',
        data: data.charts.userGrowth
          .filter(item => item.role === 'provider')
          .map(item => item.count),
        backgroundColor: chartTheme.colors[1],
        borderColor: chartTheme.colors[1],
        borderWidth: 2,
        borderRadius: 8,
        maxBarThickness: 40
      }
    ]
  } : null;

  const providerCityChartData = data?.charts?.providersByCity ? {
    labels: data.charts.providersByCity.map(item => item.city),
    datasets: [{
      data: data.charts.providersByCity.map(item => item.count),
      backgroundColor: chartTheme.colors,
      borderWidth: 0,
      hoverOffset: 4
    }]
  } : null;

  const serviceGrowthChartData = data?.charts?.serviceGrowth ? {
    labels: data.charts.serviceGrowth.map(item => 
      new Date(item.month).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
    ),
    datasets: [
      {
        label: 'عدد الطلعات',
        data: data.charts.serviceGrowth.map(item => item.count),
        borderColor: chartTheme.colors[2],
        backgroundColor: 'transparent',
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: chartTheme.colors[2]
      },
      {
        label: 'الإيرادات (ريال)',
        data: data.charts.serviceGrowth.map(item => item.revenue),
        borderColor: chartTheme.colors[3],
        backgroundColor: 'transparent',
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: chartTheme.colors[3],
        yAxisID: 'revenue'
      }
    ]
  } : null;

  const bookingStatusChartData = data?.charts?.bookingsByStatus ? {
    labels: data.charts.bookingsByStatus.map(item => bookingStatusMap[item.status]),
    datasets: [{
      data: data.charts.bookingsByStatus.map(item => item.count),
      backgroundColor: [chartTheme.colors[4], chartTheme.colors[5], chartTheme.colors[0]],
      borderWidth: 0,
      hoverOffset: 4
    }]
  } : null;

  return (
    <div className="home-container">
      {loader}
      
      {data && (
        <>
          <div className="stats-grid">
            <StatCard
              title="مزودي الخدمة"
              value={data.userStats.totalProviders}
              subValue={`مزودين جدد : ${data.userStats.newProvidersThisMonth}`}
              icon="bi bi-grid-fill"
              className="providers"
              onClick={() => navigateTo("/admin/providers")}
            />
            <StatCard
              title="المستخدمين"
              value={data.userStats.totalUsers}
              subValue={`مستخدمين جدد : ${data.userStats.newUsersThisMonth}`}
              icon="bi bi-person-fill"
              className="users"
              onClick={() => navigateTo("/admin/user")}
            />
            <StatCard
              title="الطلعات"
              value={data.services}
              subValue={`رحلات نشطة : ${data.activeBookings}`}
              icon="bi bi-award-fill"
              className="services"
              onClick={() => navigateTo("/admin/service")}
            />
            <StatCard
              title="الأرباح المحققة"
              value={`${data.provenCommession} ريال`}
              subValue={`الأرباح غير المحققة : ${data.unProvenCommession} ريال`}
              icon="bx bxs-package"
              className="profits"
            />
          </div>

          <div className="row mt-4">
            <div className="col-md-8">
              <div className="chart-card mb-4">
                <div className="card-body">
                  <h5 className="card-title">نمو المستخدمين</h5>
                  <div className="chart-container">
                    {userGrowthChartData && (
                      <Bar
                        data={userGrowthChartData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: 'top',
                              align: 'end',
                              labels: {
                                font: {
                                  family: chartTheme.fontFamily,
                                  size: 12
                                },
                                color: chartTheme.textColor,
                                usePointStyle: true,
                                padding: 20
                              }
                            },
                            tooltip: {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                              titleColor: chartTheme.textColor,
                              bodyColor: chartTheme.textColor,
                              borderColor: chartTheme.gridColor,
                              borderWidth: 1,
                              padding: 12,
                              displayColors: true,
                              usePointStyle: true,
                              titleFont: {
                                family: chartTheme.fontFamily
                              },
                              bodyFont: {
                                family: chartTheme.fontFamily
                              }
                            }
                          },
                          scales: {
                            y: {
                              beginAtZero: true,
                              grid: {
                                color: chartTheme.gridColor,
                                drawBorder: false
                              },
                              ticks: {
                                stepSize: 1,
                                font: {
                                  family: chartTheme.fontFamily
                                },
                                color: chartTheme.textColor
                              }
                            },
                            x: {
                              grid: {
                                display: false
                              },
                              ticks: {
                                font: {
                                  family: chartTheme.fontFamily
                                },
                                color: chartTheme.textColor
                              }
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="chart-card">
                <div className="card-body">
                  <h5 className="card-title">نمو الطلعات والإيرادات</h5>
                  <div className="chart-container">
                    {serviceGrowthChartData && (
                      <Line
                        data={serviceGrowthChartData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: 'top',
                              align: 'end',
                              labels: {
                                font: {
                                  family: chartTheme.fontFamily,
                                  size: 12
                                },
                                color: chartTheme.textColor,
                                usePointStyle: true,
                                padding: 20
                              }
                            },
                            tooltip: {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                              titleColor: chartTheme.textColor,
                              bodyColor: chartTheme.textColor,
                              borderColor: chartTheme.gridColor,
                              borderWidth: 1,
                              padding: 12
                            }
                          },
                          scales: {
                            y: {
                              beginAtZero: true,
                              grid: {
                                color: chartTheme.gridColor,
                                drawBorder: false
                              },
                              ticks: {
                                font: {
                                  family: chartTheme.fontFamily
                                },
                                color: chartTheme.textColor
                              }
                            },
                            revenue: {
                              position: 'right',
                              beginAtZero: true,
                              grid: {
                                display: false
                              },
                              ticks: {
                                font: {
                                  family: chartTheme.fontFamily
                                },
                                color: chartTheme.textColor,
                                callback: value => `${value} ريال`
                              }
                            },
                            x: {
                              grid: {
                                display: false
                              },
                              ticks: {
                                font: {
                                  family: chartTheme.fontFamily
                                },
                                color: chartTheme.textColor
                              }
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="chart-card mb-4">
                <div className="card-body">
                  <h5 className="card-title">توزيع مزودي الخدمة حسب المدينة</h5>
                  <div className="chart-container">
                    {providerCityChartData && (
                      <Pie
                        data={providerCityChartData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: 'bottom',
                              labels: {
                                font: {
                                  family: chartTheme.fontFamily,
                                  size: 12
                                },
                                color: chartTheme.textColor,
                                usePointStyle: true,
                                padding: 20
                              }
                            },
                            tooltip: {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                              titleColor: chartTheme.textColor,
                              bodyColor: chartTheme.textColor,
                              borderColor: chartTheme.gridColor,
                              borderWidth: 1,
                              padding: 12,
                              displayColors: true,
                              usePointStyle: true,
                              titleFont: {
                                family: chartTheme.fontFamily
                              },
                              bodyFont: {
                                family: chartTheme.fontFamily
                              }
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="chart-card">
                <div className="card-body">
                  <h5 className="card-title">حالة الحجوزات</h5>
                  <div className="chart-container">
                    {bookingStatusChartData && (
                      <Pie
                        data={bookingStatusChartData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: 'bottom',
                              labels: {
                                font: {
                                  family: chartTheme.fontFamily,
                                  size: 12
                                },
                                color: chartTheme.textColor,
                                usePointStyle: true,
                                padding: 20
                              }
                            },
                            tooltip: {
                              backgroundColor: 'rgba(255, 255, 255, 0.9)',
                              titleColor: chartTheme.textColor,
                              bodyColor: chartTheme.textColor,
                              borderColor: chartTheme.gridColor,
                              borderWidth: 1,
                              padding: 12
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

         
        </>
      )}

      {isLoading && !data && (
        <div className="stats-grid">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="stat-card skeleton" style={{ height: '160px' }}></div>
          ))}
        </div>
      )}
    </div>
  );
}