import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { baseurl, formateDate } from '../../constants';

const CategoryList = () => {
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(baseurl + `api/category?page=${pageNumber}`);
                setData(response.data.items);
                setTotalPages(Math.ceil(response.data.total / 10));
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
            setLoading(false);
        };
        fetchData();
    }, [pageNumber]);

    const handleDelete = async (id) => {
        if (window.confirm('هل أنت متأكد أنك تريد حذف هذه الفئة؟')) {
            try {
                await axios.delete(baseurl + `api/category/${id}`);
                setData(data.filter(item => item.id !== id));
            } catch (error) {
                console.error('Error deleting category:', error);
            }
        }
    };

    return (
        <div className="container-fluid p-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="m-0">قائمة الفئات</h3>
                <Link to="/admin/category/add" className="btn btn-primary">
                    <i className="fas fa-plus ms-1"></i>
                    إضافة فئة جديدة
                </Link>
            </div>

            <div className="card shadow-sm">
                <div className="card-body">
                    {loading ? (
                        <div className="text-center py-5">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">جاري التحميل...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="table-responsive">
                                <table className="table table-hover align-middle">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" className="text-center">#</th>
                                            <th scope="col">الاسم</th>
                                            <th scope="col">الوصف</th>
                                            <th scope="col">الصورة</th>
                                            <th scope="col">تاريخ الإضافة</th>
                                            <th scope="col" className="text-center">الإجراءات</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={item.id}>
                                                <td className="text-center">{(pageNumber - 1) * 10 + index + 1}</td>
                                                <td>
                                                    <div className="fw-semibold">{item.name}</div>
                                                    <small className="text-muted">#{item.id}</small>
                                                </td>
                                                <td>
                                                    <div className="text-truncate" style={{ maxWidth: '200px' }}>
                                                        {item.desc || 'لا يوجد وصف'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <img 
                                                        src={baseurl + "/uploads/" + item.image} 
                                                        className="rounded-circle" 
                                                        width={40} 
                                                        height={40} 
                                                        alt=""
                                                        style={{ objectFit: 'cover' }}
                                                    />
                                                </td>
                                                <td>
                                                    <small>{formateDate(item.createdAt)}</small>
                                                </td>
                                                <td>
                                                    <div className="d-flex gap-2 justify-content-center">
                                                        <Link 
                                                            to={"/admin/category/detail/" + item.id} 
                                                            className="btn btn-sm btn-outline-primary"
                                                            title="عرض"
                                                        >
                                                            <i className="fas fa-eye"></i>
                                                        </Link>
                                                        <Link 
                                                            to={"/admin/category/edit/" + item.id} 
                                                            className="btn btn-sm btn-outline-success"
                                                            title="تعديل"
                                                        >
                                                            <i className="fas fa-edit"></i>
                                                        </Link>
                                                        <button 
                                                            className="btn btn-sm btn-outline-danger"
                                                            onClick={() => handleDelete(item.id)}
                                                            title="حذف"
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Pagination */}
                            <nav className="mt-4">
                                <ul className="pagination justify-content-center">
                                    <li className={`page-item ${pageNumber === 1 ? 'disabled' : ''}`}>
                                        <button 
                                            className="page-link" 
                                            onClick={() => setPageNumber(prev => Math.max(prev - 1, 1))}
                                        >
                                            <i className="fas fa-chevron-right me-1"></i>
                                            السابق
                                        </button>
                                    </li>
                                    {[...Array(totalPages)].map((_, index) => (
                                        <li 
                                            key={index} 
                                            className={`page-item ${pageNumber === index + 1 ? 'active' : ''}`}
                                        >
                                            <button 
                                                className="page-link" 
                                                onClick={() => setPageNumber(index + 1)}
                                            >
                                                {index + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li className={`page-item ${pageNumber === totalPages ? 'disabled' : ''}`}>
                                        <button 
                                            className="page-link" 
                                            onClick={() => setPageNumber(prev => Math.min(prev + 1, totalPages))}
                                        >
                                            التالي
                                            <i className="fas fa-chevron-left ms-1"></i>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CategoryList;