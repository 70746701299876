import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { baseurl } from '../../constants';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import './Reports.css';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const ServicesReport = () => {
    const [stats, setStats] = useState(null);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchStats();
    }, [year, month]);

    const fetchStats = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${baseurl}api/reports/services/monthly?year=${year}&month=${month}`);
            setStats(response.data);
        } catch (error) {
            console.error('Error fetching service stats:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">جاري التحميل...</span>
                </div>
            </div>
        );
    }

    const categoryData = {
        labels: stats?.servicesPerCategory.map(item => `Category ${item.categoryId}`) || [],
        datasets: [{
            data: stats?.servicesPerCategory.map(item => item.count) || [],
            backgroundColor: [
                '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'
            ]
        }]
    };

    return (
        <div className="container-fluid p-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h3 className="m-0">تقرير الطلعات</h3>
                <div className="d-flex gap-2">
                    <select 
                        className="form-select" 
                        value={month} 
                        onChange={(e) => setMonth(parseInt(e.target.value))}
                    >
                        {Array.from({ length: 12 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                                {new Date(2024, i, 1).toLocaleString('ar-SA', { month: 'long' })}
                            </option>
                        ))}
                    </select>
                    <select 
                        className="form-select" 
                        value={year} 
                        onChange={(e) => setYear(parseInt(e.target.value))}
                    >
                        {Array.from({ length: 5 }, (_, i) => (
                            <option key={i} value={new Date().getFullYear() - i}>
                                {new Date().getFullYear() - i}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row g-4">
                <div className="col-md-6 col-lg-3">
                    <div className="card stat-card new-items">
                        <div className="card-body">
                            <h6 className="text-muted mb-2">طلعات جديدة</h6>
                            <h3 className="mb-0">{stats?.newServices || 0}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="card stat-card total-items">
                        <div className="card-body">
                            <h6 className="text-muted mb-2">إجمالي الطلعات</h6>
                            <h3 className="mb-0">{stats?.totalServices || 0}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="card stat-card active-items">
                        <div className="card-body">
                            <h6 className="text-muted mb-2">الطلعات النشطة</h6>
                            <h3 className="mb-0">{stats?.activeServices || 0}</h3>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3">
                    <div className="card stat-card average-items">
                        <div className="card-body">
                            <h6 className="text-muted mb-2">متوسط السعر</h6>
                            <h3 className="mb-0">{stats?.averagePrice?.toFixed(2) || 0} ريال</h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-6">
                    <div className="card chart-card h-100">
                        <div className="card-body">
                            <h5 className="card-title">توزيع الطلعات حسب الفئة</h5>
                            <div className="chart-container">
                                <Pie data={categoryData} options={{ 
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesReport;
